
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import dialogVisible from '../../components/Jurisdiction.vue'
import dayjs from "dayjs";

export default {
  name: "InvestmentResearchCourse",
  components: {
    dialogVisible
  },
  setup() {
    const data = reactive({
      researchList: [],
      show: false,
      pageNum: 1
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    const $store = getCurrentInstance()?.appContext.config.globalProperties.$store;
    let isBottom = true;
    const getList = () => {
      if( isBottom ) {
        $http
            .get({
              url: "/web/stockRes/tyk/list",
              params: {
                pageNum: data.pageNum++,
                pageSize: 6
              }
            })
            .then((res: any) => {
              data.researchList = data.researchList.concat(res.data.list);
              if (res.data.list.length < 6) {
                isBottom = false;
              }
            });
      }
    }
    onMounted(()=>{
        getList()
    })
    let dialogVisible = ref(false)
    const clickVideo = (item: any) => {
      if (Object.keys($store.state.loginInfo).length === 0) {
        $store.commit('setLoginStatus',1)
        return false;
      }
      if (item.payStatus) {
        if (item.isXet) {
          $http
              .post({
                url: "/ecmsVideoLive/xet/liveUrl/redirect",
                data: {
                  loginType: 1,
                  xetUrl: item.url,
                  xetResourceId: item.xetResourceId
                }
              })
              .then((res: any) => {
                const { url } = res.data
                window.open(url)
                clickView(item)
              });
        } else {
          window.open(item.url)
          clickView(item)
        }
      } else {
        dialogVisible.value = true
      }
    }
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    const clickView = (item: any) => {
      $http
          .get({
            url: "/web/stockRes/content/view",
            params: {
              type: item.type,
              id: item.id
            }
          })
          .then((res: any) => {
            console.log(res);
          });
    }
    const seeMore = ()=> {
      getList()
      // $http
      //     .get({
      //       url: "/web/stockRes/tyk/list",
      //       params: {
      //         pageNum: data.pageNum++,
      //         pageSize: 6
      //       }
      //     })
      //     .then((res: any) => {
      //       data.researchList = data.researchList.concat(res.data.list);
      //     });
    }
    return {
      data,
      dialogVisible,
      clickVideo,
      seeMore,
      dateFliter
    }
  }
}
