
import { defineComponent,getCurrentInstance,reactive,onMounted,ref } from 'vue';
import BrokenLineChart from '@/views/components/ChartLine.vue';
import { useRouter } from 'vue-router'
export default defineComponent({
  name:'MyCombination',
  components:{
    BrokenLineChart
  },
  setup(props,{emit}){
    const router = useRouter();
    const $store = getCurrentInstance()?.appContext.config.globalProperties.$store;
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;

    const myIndex = ref(0)
    //我的组合数据
    const myPortfolio = reactive({
      list: [],
      portfolioData:[{}]
    })
    const next = () => {
      if( myIndex.value === myPortfolio.list.length-1 ){
        return false;
      }
      myIndex.value = myIndex.value + 1
      getZhdt(myPortfolio?.list[myIndex.value]['productId'])
      getZxtc(myPortfolio?.list[myIndex.value]['productId'])
    }
    const prev = () => {
      if( myIndex.value === 0 ){
        return false;
      }
      myIndex.value = myIndex.value - 1
      getZhdt(myPortfolio?.list[myIndex.value]['productId'])
      getZxtc(myPortfolio?.list[myIndex.value]['productId'])
    }
    const goDetail = () => {
      router.push({
        path:"/cd",
        query:{
          pid: myPortfolio?.list[myIndex.value]['productId']
        }
      });
    }

    const getZhdt = (productId:any) => {
      //组合动态
      $http
        .get({
          url: "/product/lives",
          params: {
            productId: productId,//query.pid,//43,
            pageNum: 1,
            pageSize: 1
          }
        })
        .then((res:any) => {
          const {code, data} = res;
          if( code === 200 ){
            emit('dtList',data.list)
          }
        });
    }
    const getZxtc = (productId:any) => {
       //根据产品id获取最新调仓
      $http
        .get({
          url: "/product/opList",
          params: {
            productId: productId,
            pageNum:1,
            pageSize:4
          }
        })
        .then((res:any) => {
          const {code, data} = res;
          if( code === 200 || code === 403 ){
            emit('zxtcList',data.list || [])
          }
        });
    }

    onMounted(()=>{
      if(Object.keys($store.state.loginInfo).length !== 0){
        $http
            .get({
              url: "/product/myPortfolio"
            })
            .then((res:any) => {
              const {code} = res;
              if( code === 200 ){
                myPortfolio.list = res.data;
                if( res.data.length ){
                  myPortfolio.portfolioData = res.data[0].portfolioData.vlist_m1;
                  getZhdt(res.data[0].productId)
                  getZxtc(res.data[0].productId)
                }
              }else{
                // ElMessage.error(msg)
              }
            });
      }
    })

    return {
      myPortfolio,
      myIndex,
      goDetail,
      next,
      prev,
    }
  }
})
