
import { defineComponent } from "vue";
import * as echarts from 'echarts'

export default defineComponent({
  name: "PieChartView",
  props:{
    allData:Object
  },
  setup(props){
    console.log((props as any).allData)
    const chatClass = 'chart-'+Math.floor(Math.random() * 1000000 + 1);
    const drawLine = () => {

      const box: HTMLElement = document.getElementById(chatClass) as HTMLElement;
      let myChart = echarts.init(box);
      let list = (props as any).allData
      let data:any = [],
                yAxis:any = [],
                bdict = [];
            // let calcHeight = 0;
            for (let key in list) {
                // calcHeight += 40;
                let item = list[key];
                bdict.push({
                  name:item.name,
                  value:item.value
                });
            }
            // //动态计算高度
            // if (document.getElementById("bdict"))
            //     document.getElementById("bdict").style.height =
            //         calcHeight + "px";

            const getLineColor = function(name:any) {
                let obj = {
                    上证指数: "#3B7FEE",
                    持仓: "#3B7FEE",
                    组合: "#FE9625"
                };
                for (let key in obj) {
                    if (name.indexOf(key) > -1) {
                        return (obj as any)[key];
                    }
                }
            };

            bdict.sort(function(a, b) {
                return b.value - a.value;
            });
            console.log('dddddd',bdict)
            bdict.forEach(function(item) {
                yAxis.push(item.name);
                // console.log("item----09090", item);
                const hasColor = getLineColor(item.name) || false;
                if (hasColor) {
                    let obj = {
                        name: item.name,
                        value: Number(item.value).toFixed(2),
                        itemStyle: {
                            normal: {
                                barBorderRadius: 30,
                                color: hasColor
                            }
                        }
                    };
                    data.push(obj);
                } else {
                    let obj = {
                        name: item.name,
                        value: Number(item.value).toFixed(2),
                    };
                    data.push(obj);
                    // data.push(item.wpercent.toFixed(2));
                }
            });
            // data[0].value = '8.24';
            // console.log('对比指数1',data)
            // data.splice(2,1)
            data.map((item:any,idx:any)=>{
                if( item.value ){
                    if( Number(item.value) < 0 ){
                        item.value = Math.abs(item.value)
                        item.isAbs = true
                    }
                }else {
                    if( Number(item) < 0 ){
                        data[idx] = Math.abs(item);
                        // console.log(12111)
                        // item = 123
                        // console.log(item)
                    }
                }
            })

            let option = {
                title: {
                    text: "对比指数",
                    show: false
                },
                grid: {
                    left: "5%",
                    right: "0%",
                    bottom: "5%",
                    top: "5%",
                    containLabel: true
                },
                // tooltip: {
                //     trigger: "axis",
                //     axisPointer: {
                //         type: "none"
                //     }
                // },
                xAxis: {
                    show: false,
                    type: "value",
                },
                yAxis: [
                    {
                        type: "category",
                        inverse: true,
                        axisLabel: {
                            show: true,
                            fontSize: '16',
                            margin:20
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        data: yAxis
                    }
                ],
                series: [
                    {
                        name: "",
                        type: "bar",
                        itemStyle: {
                            normal: {
                                barBorderRadius: [20,20,20,20]
                            }
                        },
                        label: {
                            show: true,
                            color: '#000',
                            formatter: function(p:any) {
                                console.log(p)
                                let num = Number(p.value).toFixed(2)
                                num = p.data.isAbs ? `-${num}` : num
                                return num + "%"
                            }
                        },
                        // barCategoryGap:"30%",
                        barWidth: "15",
                        data
                    }
                ]
            };

      window.onresize = function () {
        myChart.resize();
      };
      myChart.setOption(option);
    }
    // onMounted(()=>{
    //   drawLine();
    // })
    return {
      chatClass,
      drawLine
    }
  }
});
