
import { defineComponent,reactive } from 'vue';
import FingerView from './components/Finger.vue'
import PopularCombination from './components/PopularCombination.vue'
import plateView from './components/Plate.vue'
import ExpertView from './components/Expert.vue'
import RecommendView from './components/Recommend.vue'
import SeniorAdviser from  './components/SeniorAdviser.vue'
import HistoricalAchievements from './components/HistoricalAchievements.vue'
// import StudentOutcomes from './components/StudentOutcomes.vue'
import FocusNews from './components/FocusNews.vue'
import { getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
// import FooterView from "@/views/components/Footer.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    // FooterView,
    FingerView,
    PopularCombination,
    plateView,
    ExpertView,
    RecommendView,
    SeniorAdviser,
    HistoricalAchievements,
    // StudentOutcomes,
    FocusNews,
  },
  setup() {

    const banner = reactive({
      top_banner: [],
      right_banner: [],
      bannerImg: require('../../assets/images/topdoudi2.jpg'),
      right_banner_bottom: require('../../assets/images/bottomdoudi.jpg'),
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    const $store = getCurrentInstance()?.appContext.config.globalProperties.$store;


    //首页banner接口对接
    $http
      .get({
        url: "/pageBanner/pc/index/banner",
      })
      .then((res:any) => {
        const {code,data} = res;
        if( code === 200 ){
          data.map((item:any)=>{
            if( item.position === 'top' ){
              banner.top_banner = item
            }
            if( item.position === 'right_center' ){
              banner.right_banner = item
            }
          })
        }else{
          // ElMessage.error(msg)
        }
      });
    let router = useRouter();
    const clickBanner = (item: any) => {
      if (Object.keys($store.state.loginInfo).length === 0) {
        $store.commit('setLoginStatus',1)
      } else {
        console.log('路由跳转')
        router.push({
          name: 'StockMarketResearch',
          params: {
            typeTage: 5
          }
        })
        // window.open(item.url);
      }
    }
    const goDetails = (item: any) => {
      if (Object.keys($store.state.loginInfo).length === 0) {
        $store.commit('setLoginStatus',1)
      } else {
        window.open(item.url);
      }
    }

    return {
      banner,
      clickBanner,
      goDetails
    }
  }
});
