
import { defineComponent,ref,reactive,getCurrentInstance } from "vue";
import type { FormRules,FormInstance } from 'element-plus'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
// import { Base64 } from 'js-base64';

import GetCodeBtn from "@/views/components/GetCodeBtn.vue";
export default defineComponent({
  name: "LoginView",
  components:{
    GetCodeBtn
  },
  setup(){
    const store = useStore();
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    const ruleFormRef = ref<FormInstance>()
    const ruleForm = reactive({
      username:''
    })
    const rules = reactive<FormRules>({
      username: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' },
      ],
      verifyCode: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
      ],
      password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
      ],
      repeatpassword: [
        { required: true, message: '请输入确认密码', trigger: 'blur' },
      ],

    })

    //提交表单
    const submitForm = async (formEl: FormInstance | undefined) => {
      console.log(formEl);

      if (!formEl) return
      await formEl.validate((valid, fields) => {
        console.log(fields);
        if (valid) {
          let obj = JSON.parse(JSON.stringify(ruleForm))
          obj.password = window.btoa(window.encodeURI(obj.password))
          delete obj.repeatpassword;
          $http
            .post({
              url: "/login/register",
              data:obj
            })
            .then((res:any) => {
              const {code, msg} = res;
              if( code === 200 ){
                //store.commit('setLoginInfo')
                onSubmit({
                  username:obj.username,
                  password:obj.password
                })
                ElMessage.success(msg)
              }else{
                ElMessage.error(msg)
              }
            });
        } else {
          console.log('error submit!', fields)
        }
      })
    }
    const onSubmit = (params:any) => {
      $http
        .post({
          url: "/login/logon",
          data:params
        })
        .then((res:any) => {
          const {code, msg, data} = res;
          if( code === 200 ){
            localStorage.setItem('loginInfo',JSON.stringify(data))
            store.commit('setLoginInfo', data)
            ElMessage.success(msg)
          }else{
            window.location.reload()
          }
        });
    }

    //关闭弹窗
    const handleClose = () => {
      store.commit('setLoginStatus',1)
    }

    return {
      ruleForm,
      rules,
      dialogVisible:true,
      ruleFormRef,
      handleClose,
      submitForm
    }
  }
});
