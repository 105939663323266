
import LineChartRed from '../../components/LineRedChart.vue'
import { getCurrentInstance,reactive } from "vue";
export default {
  components: { LineChartRed },
  name: "HistoricalAchievements",
  setup(){
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
     //热门个股接口
    const tableData = reactive({
      list: [],
      hotData:[]
    })
    $http
      .get({
        url: "/product/portfolio/history"
      })
      .then((res:any) => {
        const {code,data} = res;
        if( code === 200 ){
          tableData.list = data;
        }else{
          // ElMessage.error(msg)
        }
    });

    return {
      tableData
    }
  }


}
