import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6baf1b8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reference" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "left" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "top" }
const _hoisted_7 = { class: "center" }
const _hoisted_8 = { class: "bottom" }
const _hoisted_9 = { class: "left" }
const _hoisted_10 = { class: "button-box" }
const _hoisted_11 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_visible = _resolveComponent("dialog-visible")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.internalList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item-box",
          key: item.id,
          onClick: ($event: any) => ($setup.goDetails(item))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: item.cover,
              alt: ""
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(item.smallContent), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(item.label), 1)
                ]),
                _createElementVNode("span", null, _toDisplayString($setup.dateFliter(item.publishTime,'MM-DD HH:mm')), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", null, "阅读量：" + _toDisplayString(item.playView) + "次", 1)
              ])
            ])
          ])
        ], 8, _hoisted_2))
      }), 128))
    ])), [
      [_directive_infinite_scroll, $setup.seeMore]
    ]),
    _createVNode(_component_dialog_visible, {
      dialogVisible: $setup.dialogVisible,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.dialogVisible = false))
    }, null, 8, ["dialogVisible"])
  ], 64))
}