
import { defineComponent,reactive,ref } from 'vue'
import { useRoute } from "vue-router";

interface tabsType {
  text: string,
  isChecked: boolean
}

export default defineComponent({
  name: "TabsView",
  setup(props,{emit}) {
    const tabsData: tabsType[] = reactive([
      {
        text: '最近更新',
        isChecked: false,
        type: 0
      },
      {
        text: '月度策略',
        isChecked: false,
        type: 5
      },
      {
        text: '股市脉动',
        isChecked: false,
        type: 3
      },
      {
        text: '大师课',
        isChecked: false,
        type: 1
      },
      {
        text: '投研课',
        isChecked: false,
        type: 2
      },
      {
        text: '财富内参',
        isChecked: false,
        type: 4
      }
    ]);
    const active = ref(0)
    let route = useRoute();
    const handleClick = (item:any, index:any) =>{
      active.value = index;
      emit('clickTab', item)
    }
    let routeType = route.params.typeTage;
    if (routeType) {
      handleClick({type: 4, text: '财富内参', isChecked: false, }, 5)
    }
    return {
      tabsData,
      active,
      handleClick
    }
  }
})
