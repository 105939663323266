import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3507056a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list = _resolveComponent("list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_list, {
      tableList: _ctx.tableList.list,
      onKf: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('kf')))
    }, null, 8, ["tableList"])
  ]))
}