<template>
  <div class="finger">
    <div v-for="(item,idx) in tableData.list" :key='idx'>
      <div class="top">
        <span>{{item.name}}</span>
        <span :class="$utils.colorClass(item.incr)">{{item.price}}</span>
      </div>
      <div class="bottom">
        <span :class="$utils.colorClass(item.incr)">{{item.incr}}%</span>
        <span :class="$utils.colorClass(item.change)">{{item.change}}</span>
      </div>
    </div>
    <!-- <div></div>
    <div></div>
    <div></div>
    <div></div> -->
  </div>
</template>

<script>
import { getCurrentInstance,reactive } from 'vue';
export default {
  name: "FingerView",
  setup(){
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    //热门个股接口
    const tableData = reactive({
      list: []
    })
    $http
      .get({
        url: "/product/boardData"
      })
      .then((res) => {
        const {code,data} = res;
        if( code === 200 ){
          let arr = [];
          for (const key in data) {
            arr.push(data[key])
          }
          tableData.list = arr;
          // ElMessage.success(msg)
          // hotdata.list = data;
        }else{
          // ElMessage.error(msg)
        }
      });
    return {
      tableData
    }
  }
}
</script>

<style scoped lang="scss">
.finger {
  width: 100%;
  height: 100px;
  background: #FFFFFF;
  display: flex;
  > div {
    margin: 19px 0;
    flex: 1;
    border-right: 1px solid #DEDEDE;
    &:last-child {
      border-right: none;
    }
    > .top {
      display: flex;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 18px;
      > span {
        display: block;
        &:first-child {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }
        &:last-child {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FB160C;
          line-height: 20px;
        }
      }
    }
    > .bottom {
      //display: flex;
      //justify-content: right;
      margin-top: 20px;
      padding-right: 18px;
      > span {
        display: block;
        &:first-child {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FB160C;
          line-height: 20px;
          //margin-right: 21px;
          float: right;
        }
        &:last-child {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FB160C;
          line-height: 20px;
          float: right;
          margin-right: 21px;        }
      }
    }
  }
}
</style>

