
import {getCurrentInstance, onMounted, reactive, ref} from 'vue'
import { useRouter } from 'vue-router'
import dialogVisible from '../../components/Jurisdiction.vue'
import dayjs from "dayjs";
export default {
  name: "WealthInternalReference",
  components: {
    dialogVisible
  },
  setup() {
    const data = reactive({
      internalList: [],
      pageNum: 1
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    const $store = getCurrentInstance()?.appContext.config.globalProperties.$store;
    let isBottom = true;
    const getList = () => {
      if( isBottom ){
        $http
            .get({
              url: "/web/stockRes/cfnc/list",
              params: {
                pageNum: data.pageNum++,
                pageSize: 10
              }
            })
            .then((res: any) => {
              data.internalList = data.internalList.concat(res.data.list);
              if( res.data.list.length < 10 ){
                isBottom = false;
              }
            });
      }

    }
    let dialogVisible = ref(false)
    onMounted(()=>{
      getList()
    })
    const router = useRouter();
    const goDetails = (item: any) => {
      if (Object.keys($store.state.loginInfo).length === 0) {
        $store.commit('setLoginStatus',1)
        return false;
      }
      if (item.payStatus) {
        clickView(item)
        router.push({
          path: 'wealth',
          query: { wealthId: item.id}
        })
      } else {
        dialogVisible.value = true
      }
    }
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    const clickView = (item: any) => {
      $http
          .get({
            url: "/web/stockRes/content/view",
            params: {
              type: item.type,
              id: item.id
            }
          })
          .then((res: any) => {
            console.log(res);
          });
    }
    const seeMore = ()=> {
      getList()
    }
    return {
      data,
      dialogVisible,
      goDetails,
      seeMore,
      dateFliter
    }
  }
}
