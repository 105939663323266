import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ebcde82"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "data-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_tab = _resolveComponent("c-tab")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      id: _ctx.chatClass,
      style: _normalizeStyle({'height' : _ctx.chartHeight ? _ctx.chartHeight : '200px'})
    }, null, 12, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeLine.list, (item, idx) => {
        return (_openBlock(), _createElementBlock("span", { key: idx }, _toDisplayString(item), 1))
      }), 128))
    ]),
    _createVNode(_component_c_tab, {
      onTabClick: _ctx.tabClick111,
      itemData: _ctx.itemData
    }, null, 8, ["onTabClick", "itemData"])
  ]))
}