import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7df944df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tableClass" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_one_table = _resolveComponent("c-one-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_c_one_table, {
        tableData: _ctx.tableData.list,
        onLook: _cache[0] || (_cache[0] = (item)=>{_ctx.$emit('look',item)})
      }, null, 8, ["tableData"])
    ])
  ]))
}