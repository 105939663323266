import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '../views/Layout.vue'
import HomeView from '../views/home/Home.vue'
import DetailView from '../views/detail/Detail.vue'
import StockMarketResearch from '../views/StockMarketResearch/index.vue'
import IAdviserPerson from '../views/IAdviserPerson/Index.vue'
import InformationDetails from '../views/InformationDetails/Index.vue'
import Combination from '../views/Combination/Index.vue'
import CDetail from '../views/Combination/Detail.vue'
import SevenDetailPage from '../views/SevenDetailPage/index.vue'
import WealthDetails from '../views/StockMarketResearch/components/WealthDetails.vue'
import MonthlyStrategyDetails from '../views/StockMarketResearch/components/MonthlyStrategyDetails.vue'
import Login from '../views/Login/Index.vue'
import NotFound from '../views/404.vue'
import Cxclass from './cxclass.js';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children:[
      {
        path: '/',
        name: 'home',//首页
        component: HomeView,
      },
      {
        path: '/stock',
        name: 'StockMarketResearch', // 股市深研
        component: StockMarketResearch,
      },
      {
        path: '/wealth',
        name: 'WealthDetails',// 股市深研详情页
        component: WealthDetails,
      },
      // {
      //   path: '/MonthlyStrategyDetails',
      //   name: 'MonthlyStrategyDetails',// 月度策略详情页
      //   component: MonthlyStrategyDetails,
      // },
      {
        path: '/seven',
        name: 'SevenDetailPage', // 7*24
        component: SevenDetailPage,
      },
      {
        path: '/detail',
        name: 'detail',//首页
        component: DetailView,
      },
      {
        path: '/iap',
        name: 'iap',//首页
        component: IAdviserPerson,
      },
      {
        path: '/infod',
        name: 'InformationDetails',//资讯详情
        component: InformationDetails,
      },
      {
        path: '/combination',
        name: 'Combination',//组合列表
        component: Combination,
      },
      {
        path: '/cd',
        name: 'CDetail',//组合列表
        component: CDetail,
      },
    ]
  },
    // 移动端与PC端适配详情页面
  {
    path: '/MonthlyStrategyDetails',
    name: 'MonthlyStrategyDetails',// 月度策略详情页
    component: MonthlyStrategyDetails,
  },
  {
    path: '/cxclass',
    name: 'cxclass',
    meta: {},
      children: Cxclass,
      redirect: '/cxclass/cx001',
      component: () => import('@/views/cxclass/index.vue')
  },
  {
    path: '/sinapay', // 新浪移动端测试支付专用
    name: 'sinapay',
    meta: {},
    component: () => import('@/views/sina/SinaPay.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
