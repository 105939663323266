import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a89ff2e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list = _resolveComponent("list")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tableList.list.length)
      ? (_openBlock(), _createBlock(_component_list, {
          key: 0,
          tableList: _ctx.tableList.list,
          onKf: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('kf')))
        }, null, 8, ["tableList"]))
      : (_openBlock(), _createBlock(_component_el_empty, {
          key: 1,
          description: "暂无数据"
        }))
  ]))
}