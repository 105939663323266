

import dayjs from "dayjs";
import { getCurrentInstance,reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: "RecommendView",
  setup(){
    const data = reactive({
      list: []
    });
      const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
      $http
          .get({
            url: "/ecmsArticle/recommend/list",
          })
          .then((res:any) => {
            data.list = res.data;
          });
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    const router = useRouter();
    const goDetails = (item: any) => {
      window.scrollTo(0,0)
      router.push({
        path: 'infod',
        query: { infodId: item.id}
      })
    }
    const seeMore = ()=> {
      let lastId = ''
      data.list.forEach((item: any, index) => {
        if (data.list.length - 1 === index) {
          lastId = item.id
        }
      })
      $http
          .get({
            url: "/ecmsArticle/recommend/list",
            params: {
              lastId,
              limit: 6
            }
          })
          .then((res:any) => {
            data.list = data.list.concat(res.data)
          });
    }
    return {
      data,
      dateFliter,
      goDetails,
      seeMore
    }

  }
}
