
import { defineComponent,ref,getCurrentInstance,reactive } from 'vue';
import LecturerList from './Lecturer.vue'
import { useRouter } from "vue-router";
export default defineComponent({
  name: 'CSeven',
  components:{
    LecturerList
  },
  setup() {
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http
    const router = useRouter();
    const query = (router as any).currentRoute.value.query
    const tableList = reactive({
      list: []
    })
    let showDialogDetail = ref(false)
    let info = reactive({
      value: {}
    })

    //行业课列表
    $http
      .get({
        url: "/web/stockRes/audio/list",
        params: {
          type: 1,
          productId:query.pid//3
        }
      })
      .then((res:any) => {
        const {code, data} = res;
        if( code === 200 ){
          tableList.list = data.list;
        }
      });

    const getDetail = (item:any) => {
      $http
      .get({
        url: "/web/stockRes/audio/detail",
        params: {
          id: item.id,
        }
      })
      .then((res:any) => {
        const {code, data} = res;
        if( code === 200 ){
          console.log(data)
          info.value = data;
        }
      });
    }


      const toDetails = (item:any) => {
         showDialogDetail.value = true;
         info.value = item;
         getDetail(item)
            // console.log('item', item);
            // // console.log("item", item);
            // if (this.$storage.get('userid')) {
            //     // alert("已经登录");
            //     if (item.show == 2) {
            //         this.$showChatQrCode(this);
            //         return;
            //     }
            //
            //     this.showDialogDetail = true;
            // } else {
            //     // 未登录
            //     this.openLoginBox(true);
            //     return;
            // }
            //
            // this.info = {};
            // this.getDetails(item);

        }

    return {
      tableList,
      showDialogDetail,
      info,
      toDetails
    }
  }

});
