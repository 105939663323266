
import { defineComponent,ref,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "CTitle",
  props:{
    title:{
      type: String,
    },
    isMore:{
      type: String,
    },
    closed:{
      type: Boolean,
    },
    des:{
      type: String,
    }
  },
  setup(){
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http
    const router = useRouter();
    const query = (router as any).currentRoute.value.query
    const dialogVisible = ref(false);
    const intro = ref('')

    const handleClick = () => {
      dialogVisible.value = true
      //组合简介
      $http
        .get({
          url: "/product/intro",
          params: {
            productId: query.pid,
          }
        })
        .then((res:any) => {
          const {code, data} = res;
          if( code === 200 ){
            intro.value = data.intro
          }
        });
    }

    return {
      dialogVisible,
      intro,
      handleClick
    }
  }
});
