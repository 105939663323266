
import {defineComponent, onMounted, reactive, getCurrentInstance} from "vue";
import * as echarts from 'echarts'
import CTab from './CTab.vue'
// import CChartDes from './CChartDes.vue'

export default defineComponent({
  name: "LineChartView",
  components:{
    CTab,
    // CChartDes
  },
  props:{
    itemData:Object,
    chartHeight:String
  },
  mounted(){
    // vlist_all

  },
  setup(props, { emit }){
    const chatClass = 'chart-'+Math.floor(Math.random() * 1000000 + 1);
    const $utils = getCurrentInstance()?.appContext.config.globalProperties.$utils;
    const chartDes = reactive({
      list:[]
    });

    let timeLine = reactive({
      list:[]
    });

    const getServies = ( params:any ) => {

      let dataMap:any = {};
      let serveiesMap:any = [];

      //数据拼装  {0:[],1:[]}
      console.log(params)
      params.map((item:any,idx:any)=>{
        let index = 0
        for (const itemKey in item) {
          if( itemKey !== 'date' ){
            if( !dataMap[itemKey] ){
                dataMap[itemKey] = [item[itemKey]]
            } else {
              dataMap[itemKey].push(item[itemKey])
            }
          }
          index++
        }
      })

      chartDes.list = dataMap;
      console.log(dataMap)

      //几条线循环几次
      for (const dataMapKey in dataMap) {
        serveiesMap.push({
            name:dataMapKey,
            color: $utils.getLineColor((props as any).itemData.productName == dataMapKey ? '组合' : dataMapKey),
            type: "line",
            symbol: "none",
            data: dataMap[dataMapKey],
            areaStyle: getAreaStyle(dataMapKey),
            itemStyle: {
                normal: {
                    lineStyle: {
                      width: (props as any).itemData.productName == dataMapKey ? 2.5 : 1.8// 0.1的线条是非常细的了
                    }
                }
            },
          })
      }
      return serveiesMap;
    }

    const getAreaStyle = ( name:any ) => {
      if( name.indexOf('指数') === -1 ){
        return null
      }
      return {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(59,127,238, 0.12)",
                      },
                      {
                        offset: 1,
                        color: "rgba(59,127,238, 0)",
                      },
                    ],
                    false
                ),
                // shadowColor: "rgba(109, 126, 0, 0.5)", //阴影颜色
                // shadowBlur: this.WidthAdaptive(50), //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            }
    }

    const drawLine = (portfolioData:any) => {

      let timeList:any = [];
      let xData:any = [];
      // let xSeriesData1:any = [];
      // let xSeriesData2:any = [];

      let mapServies:any = getServies(portfolioData);

      let mapLegend = []
      for (const mapServiesKey in mapServies) {
        mapLegend.push({
          name:mapServies[mapServiesKey].name,
        });
      }
      console.log(mapServies,mapLegend)
      portfolioData.map((item:any,idx:any)=>{
        xData.push(item['date'])
        // xSeriesData1.push(item[(props as any).itemData.basic.name].toFixed(2))
        // xSeriesData2.push(item['上证指数'].toFixed(2))
        // Math.floor(portfolioData.length / 2) === idx ||
        // for (const itemKey in item) {
        //   if( itemKey != 'date' ){
        //
        //   }
        // }
        if( idx === 1 ||  idx === portfolioData.length-1 ){
          timeList.push(item['date'])
        }
        // if( item.length ){
        //   item.map((innerItem:any)=>{
        //     if( innerItem.text === '上证指数' ){
        //       xSeriesData2.push(innerItem.value.toFixed(2))
        //     }else if( innerItem.text === 'date' ){
        //       xData.push(innerItem.value)
        //     }else if( innerItem.text === (props as any).itemData.productName ){
        //       xSeriesData1.push(innerItem.value.toFixed(2))
        //     }
        //   })
        //
        //   if( idx === 1 ||  idx === portfolioData.length-1 ){
        //     timeList.push(item[0].value)
        //   }
        // }else{
        //   xData.push(item['date'])
        //   xSeriesData1.push(item[(props as any).itemData.basic.name].toFixed(2))
        //   xSeriesData2.push(item['上证指数'].toFixed(2))
        //   // Math.floor(portfolioData.length / 2) === idx ||
        //   if( idx === 1 ||  idx === portfolioData.length-1 ){
        //     timeList.push(item['date'])
        //   }
        // }
      })

      timeLine.list = timeList;

      // let myChart = echarts.init(document.getElementById("zhexiantu"));
      // const box: HTMLElement = document.getElementById("brokenLineChart") as HTMLElement;
      const box: HTMLElement = document.getElementById(chatClass) as HTMLElement;
      let myChart = echarts.init(box);

      let option = {
        backgroundColor: "transparent",
        // color: ["#fff","green"],
        grid: {
          left: "0%",
          right: "0%",
          top: "45px",
          bottom: "0",
          containLabel: true,
        },
        legend:{
          data:mapLegend,
          left:0,
          icon:'roundRect',
          itemWidth:12,
          itemHeight:3,
          y:'top',
          textStyle: {
            fontSize: '14px',
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            fontWeight: 400,
            color: '#666',
            backgroundColor: 'rgba(0, 0, 0, 0)',  //透明的背景颜色
            padding: [0,32,0,0]
          },
        },
        tooltip : {
          trigger: 'axis',
          axisPointer: {
              type: 'cross',
              label: {
                 backgroundColor: '#6a7985'
              }
          },
          formatter:function(param:any){
            let text = ''
            for (let i = 0; i < param.length; i++) {
              const item = param[i]
              text += `<div>
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${item.seriesName}：</span>
                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${item.data > 0 ? '+' + item.data : item.data }</span>
                    </div>`
            }
            return text
          }
        },
        xAxis: {
          axisTick: {show: false},
          boundaryGap: false,
          axisLabel: {
            margin: 10,
            // interval:0,
            formatter () { // 第一个参数没有用 所以用_代替
              return '';
              // console.log(_, index)
              // return _;
              // if (index === 1) {
              //   return _;
              // } else if (index === Math.floor(xData.length / 2)){
              //   return _;
              // } else if (index === xData.length-1){
              //   return _;
              // }
            },
            textStyle: {color: "#CCCCCC"},
          },
          axisLine: {
            // onZero: false, // X轴脱离Y轴0刻度
            lineStyle: {
              color: "#fafafa",
              type: "solid"
            },
          },

          data: xData
          //   data: ["2018年", "2019年", "2020年", "2021年", "2022年", "2023年"],
        },
        yAxis: {
          name: "",
          nameTextStyle: {
            color: "#CCCCCC",
            // fontSize: this.WidthAdaptive(12),
            // padding: this.WidthAdaptive(8),
          },
          axisTick: {show: false},
          axisLabel: {
            // margin: this.WidthAdaptive(10),
            // textStyle: {color: "#CCCCCC", fontSize: this.WidthAdaptive(12)},
          },
          axisLine: {
            show: false,
            lineStyle: {color: "#cccccc", type: "solid"},
          },
          splitLine: {
            lineStyle: {
              type: "solid",
              color: "#fafafa",
            },
          },
          boundaryGap: [0, 0.1],
        },
        series: mapServies
        //     [
        //   {
        //     name:(props as any).itemData.productName || (props as any).itemData.basic.name,
        //     color: '#F51824',
        //     type: "line",
        //     // symbolSize: this.WidthAdaptive(6),
        //     symbol: "none",
        //     data: xSeriesData1,
        //     areaStyle: {
        //       //区域填充样式
        //       normal: {
        //         //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
        //         color: new echarts.graphic.LinearGradient(
        //             0,
        //             0,
        //             0,
        //             1,
        //             [
        //               {
        //                 offset: 0,
        //                 color: "rgba(244,129,33, 0.12)",
        //               },
        //               {
        //                 offset: 1,
        //                 color: "rgba(255,255,255,0)",
        //               },
        //             ],
        //             false
        //         ),
        //         // shadowColor: "rgba(109, 126, 0, 0.5)", //阴影颜色
        //         // shadowBlur: this.WidthAdaptive(20), //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
        //       },
        //     },
        //   },
        //   {
        //     name:'上证指数',
        //     color: '#0185FB',
        //     type: "line",
        //     // symbolSize: this.WidthAdaptive(6),
        //     symbol: "none",
        //     data: xSeriesData2,
        //     areaStyle: {
        //       //区域填充样式
        //       normal: {
        //         //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
        //         color: new echarts.graphic.LinearGradient(
        //             0,
        //             0,
        //             0,
        //             1,
        //             [
        //               {
        //                 offset: 0,
        //                 color: "rgba(59,127,238, 0.12)",
        //               },
        //               {
        //                 offset: 1,
        //                 color: "rgba(59,127,238, 0)",
        //               },
        //             ],
        //             false
        //         ),
        //         // shadowColor: "rgba(109, 126, 0, 0.5)", //阴影颜色
        //         // shadowBlur: this.WidthAdaptive(50), //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
        //       },
        //     },
        //   },
        // ],
      };
      // window.onresize = function () {
      //   myChart.resize();
      // };
      myChart.setOption(option);

      myChart.on('legendselectchanged', function() {
        if( window ){
          (window as any).event.stopPropagation();
        }
      })
    }

    //我的组合按钮切换
    const tabClick111 = (list:any) => {
      console.log('切换tab')
      emit('tabClickPort')
      // if( props.itemData && props.itemData.portfolioData[item.id] ){
        drawLine(list)
      // }
      // console.log(num,id)
      // console.log(myPortfolio)
      // console.log(myPortfolio.portfolioData)
      // myPortfolio.portfolioData = myPortfolio.list[data.tabTabIdx]['portfolioData'][id];
    }

    onMounted(() => {
      if( props.itemData ){
        drawLine(props.itemData.vlist_m1 || props.itemData.portfolioData.vlist_m1)
      }
    })
    // watch(props, function (value, oldvalue) {
    //   console.log('间听到了',value,chatClass)
    //   drawLine(value.itemData);
    // })
    return {
      chatClass,
      timeLine,
      chartDes,
      tabClick111
    }
  },

});
