
import {defineComponent, ref, getCurrentInstance, reactive, onMounted} from 'vue';
import ChartPie from '../../components/ChartPie.vue'
import ChartHistogram from '../../components/ChartHistogram.vue'
import ChartPross from '../../components/ChartPross.vue'
import { useRouter } from "vue-router";
import * as echarts from 'echarts';
export default defineComponent({
  name: 'CombinatorialAnalysis',
  components:{
    ChartPie,
    ChartHistogram,
    ChartPross
  },
  props:{
    allData:Object
  },
  setup: function () {
    const router = useRouter();
    const query = (router as any).currentRoute.value.query
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http

    const chartOne = ref(null);
    const chartTwo = ref(null);
    const chartThree = ref(null);

    const mapsData = reactive({
      list: {},
      // mappingData: []
    })

    //根据组合产品Id 获取历史表现数据
    $http
        .get({
          url: "/product/analysis",
          params: {
            productId: query.pid,//43,
          }
        })
        .then((res: any) => {
          const {code, data} = res;
          if (code === 200) {
            mapsData.list = data;
            console.log(data.concept, '--------------------data')
            //tableData.list = data;
          }
        });
    // ------ 绘图Fn--------------
    const mappingFn = () => {

      let chartDom = document.getElementById('main')!;
      chartDom.removeAttribute('_echarts_instance_');

      type EChartsOption = echarts.EChartsOption;

      let myChart = echarts.init(chartDom);
      let option: EChartsOption | undefined;
      myChart.showLoading();
      $http
          .get({
            url: "/product/analysis",
            params: {
              productId: query.pid,//43,
            }
          })
          .then((res: any) => {
            const {code, data} = res;
            if (code === 200) {
              console.log(data.concept, '处理前的数据')
              // let newData: any = [];
              // data.concept.map((item: any) => {
              //   let objItem = {
              //     name: item.conceptName,
              //     value: Number(item.incr)
              //   }
              //   newData.push(objItem)
              // })
              if (data.concept == undefined) {
                return false;
              }
              let newData: any = [];
              data.concept.forEach((item: any, index: any) => {
                let green = "#27B666";
                let red = "#E22C1F";
                let white = "#fff";
                let obj = {
                  name: item.conceptName,
                  value: Math.abs(item.incr),
                  only: item.count,
                  isNegative: Number(item.incr),
                  // isNegative: Number(item.incr) > 0 ? "+" : Number(item.incr) < 0 ? "-" : "",
                  label: {
                    show: true,
                    fontSize: 15,
                    color: '#fff',
                    // align: "center",
                    // verticalAlign: "middle",
                    formatter: function (a: any) {
                      let prefix = Number(a.data.isNegative) > 0 ? "+" : "";
                      return (
                          a.name +
                          "\n" +
                          a.data.only +
                          "只" +
                          "\n" +
                          prefix +
                          Number(a.data.isNegative).toFixed(2) +
                          "%"
                      );
                    },
                  },
                  itemStyle: {
                    color: Number(item.incr) < 0 ? green : Number(item.incr) == 0 ? '#ccc' : red,
                  },
                };
                if (index < 20) {
                  newData.push(obj);
                }
              });
              newData.sort((a:any, b: any) => {
                return a.value - b.value
              })
              let dataList: any = [];
              newData.map((item: any) => {
                let arr = {
                  name: item.name,
                  label: item.label,
                  only: item.only,
                  itemStyle: item.itemStyle,
                  isNegative: item.isNegative,
                  value: item.value > 50 ? 5.5 : item.value < 30 ? 2.5 : 3.5
                };
                dataList.push(arr)
              })
              myChart.hideLoading();
              myChart.setOption(
                  (option = {
                    series: [
                      {
                        nodeClick: "link",
                        type: "treemap",
                        width: "100%",
                        height: "100%",
                        squareRatio: 1.1,
                        breadcrumb: {
                          show: false,
                        },
                        itemStyle: {
                          gapWidth: 5,
                        },
                        label: {
                          color: "#000",
                          // formatter: `{b}\n{c}%`,
                          // position: "insideBottomLeft",
                        },
                        roam: false,
                        silent: false,
                        color: ["#E22C1F"],
                        data: dataList,
                      },
                    ],
                  })
              );
            }
          });
      // window.addEventListener("resize", function () {
      //   myChart.resize();  //使图表自适应窗口的大小
      // });
      option && myChart.setOption(option);
    }
    // -----------------------

    const init = () => {
      setTimeout(() => {
        // console.log('chartTwo',chartOne);
        (chartOne as any).value?.drawLine();
        (chartTwo as any).value?.drawLine();
        (chartThree as any).value?.drawLine();
        mappingFn()
      }, 300)
    }
    return {
      init,
      mapsData,
      chartOne,
      chartTwo,
      chartThree
    }
  }
});
