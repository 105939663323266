
import { defineComponent,reactive,getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import CTwoTable from './CTwoTable.vue'

export default defineComponent({
  name: 'CTwo',
  components:{
    CTwoTable
  },
  // props:{
  //   allData: Object
  // },
  setup(props){
    const tableData = reactive({
      list: []
    })

    const router = useRouter();
    const query = (router as any).currentRoute.value.query
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http

    //根据组合产品Id 获取最新调仓数据
    $http
      .get({
        url: "/product/opList",
        params: {
          productId: query.pid,//43,
        }
      })
      .then((res:any) => {
        const {code, data} = res;
        if( code === 200 ){
          tableData.list = data;
        }
      });

    // watch(props, function (value) {
    //   tableData.list = (value as any).allData.oplist
    // })

    return {
      tableData
    }
  }
});
