
import { defineComponent,getCurrentInstance,reactive,watch } from 'vue';
import CTitle from '../../components/CTitle.vue'
import List from '../components/List.vue'
import COneTable from '../components/COneTable.vue'
import CSixTable from '../components/CSixTable.vue'
import CTwoTable from "@/views/Combination/components/CTwoTable.vue";
// import { useRouter,useRoute } from "vue-router";
export default defineComponent({
  name: 'DrawerIndex',
  // props:['params','drawer'],
  props:{
    params:Object,
    drawer:Boolean,
    type:String
  },
  components:{
    CTitle,
    List,
    COneTable,
    CTwoTable,
    CSixTable
  },
  setup(props) {
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http
    // const router = useRouter();
    // const query = (router as any).currentRoute.value.query
    const tableData = reactive({
      list: {}
    })
    console.log(props)
    const getDetail = () => {
      //根据股票code代码获取股票详情
      $http
        .get({
          url: "/product/stock",
          params: props.params
          // params:{
          //   productId:2,
          //   code: '000799'//'000625'
          // }
        })
        .then((res:any) => {
          const {code, data} = res;
          if( code === 200 ){
            if( data.lives ){
              data.lives.map((item:any)=>{
                item.stockList = [{
                  stockName:data.name
                }]
              })
            }
            tableData.list = data;
          }
        });
    }
    getDetail()

    watch(props, function (value) {
      // tableData.list = (value as any).allData.holding
      if( value.drawer ){
        getDetail()
      }
    })

    return {
      tableData
    }
  }
})
