import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f157a4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.tableData,
    style: {"width":"100%"},
    "row-class-name": _ctx.tableRowClassName,
    "empty-text": "暂无数据",
    border: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "name",
        label: "股票名称",
        width: "115",
        sortable: "",
        align: "left"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("p", null, _toDisplayString(scope.row.name), 1),
          (scope.row.code)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(scope.row.code.slice(0,6)), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "rtime",
        label: "日期",
        sortable: "",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          (scope.row.rtime)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$utils.dealUpDate(scope.row.rtime.split('-')[0])), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "op",
        label: "操作类型",
        sortable: "",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_el_tag, {
            onClick: ($event: any) => (_ctx.$emit('look',scope.row)),
            type: _ctx.$utils.opMapColor(scope.row.op).type
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$utils.opMapColor(scope.row.op).label), 1)
            ]),
            _: 2
          }, 1032, ["onClick", "type"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "rinfo",
        label: "操作详情",
        width: "150",
        sortable: "",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_popover, {
              placement: "top-start",
              width: 200,
              effect: "dark",
              trigger: "hover",
              content: scope.row.info
            }, {
              reference: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.info), 1)
              ]),
              _: 2
            }, 1032, ["content"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "ramt",
        label: "成交额",
        sortable: "",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$utils.mktcap(scope.row.ramt, 10000, 2, '万')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "rprice",
        label: "操作价格",
        sortable: "",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$utils.numberFixed(scope.row.rprice, 3)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "rvol",
        label: "成交量",
        sortable: "",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$utils.mktcap(scope.row.rvol, 1, 0, '股')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "fee",
        label: "手续费",
        sortable: "",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$utils.numberFixed(scope.row.fee, 2)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "tax",
        label: "税费",
        sortable: "",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$utils.numberFixed(scope.row.tax, 2)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data", "row-class-name"]))
}