import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f10a0a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-box" }
const _hoisted_2 = { class: "tit" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.isMore)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: "/iap",
          class: "link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isMore ? _ctx.isMore : '查看更多') + " >", 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.closed)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, "x"))
      : _createCommentVNode("", true),
    (_ctx.des)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: "des",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
        }, _toDisplayString(_ctx.des), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_el_dialog, {
      "custom-class": "login",
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: _ctx.title + '简介',
      top: "10vh",
      width: "40%",
      "before-close": _ctx.handleClose
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          innerHTML: _ctx.intro,
          class: "intro-content"
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "before-close"])
  ]))
}