import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e334a1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pulsation" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "top-box" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "center-box" }
const _hoisted_6 = { class: "bottom-box" }
const _hoisted_7 = { class: "left" }
const _hoisted_8 = { class: "button-box" }
const _hoisted_9 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_visible = _resolveComponent("dialog-visible")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.monthlyList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "item-box",
        key: item.id,
        onClick: ($event: any) => ($setup.clickVideo(item))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: item.cover,
            alt: ""
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", null, _toDisplayString(item.label), 1)
            ]),
            _createElementVNode("span", null, _toDisplayString($setup.dateFliter(item.publishTime,'MM-DD HH:mm')), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", null, "阅读量：" + _toDisplayString(item.playView) + "次", 1)
          ])
        ])
      ], 8, _hoisted_2))
    }), 128)),
    _createVNode(_component_dialog_visible, {
      dialogVisible: $setup.dialogVisible,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.dialogVisible = false))
    }, null, 8, ["dialogVisible"])
  ])), [
    [_directive_infinite_scroll, $setup.seeMore]
  ])
}