import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27db14e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsData, (item, index) => {
      return (_openBlock(), _createElementBlock("ul", {
        key: index,
        onClick: ($event: any) => (_ctx.handleClick(item,index)),
        class: _normalizeClass(_ctx.active === index ? 'active' :'')
      }, [
        _createElementVNode("li", null, _toDisplayString(item.text), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}