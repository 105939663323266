
import { defineComponent,ref,getCurrentInstance,reactive,watch,onMounted } from 'vue';
import LecturerList from './Lecturer.vue'
import DisclaimerText from './DisclaimerText.vue'

export default defineComponent({
  name: 'newsList',
  components:{
    LecturerList,
    DisclaimerText
  },
  props:['tableList','handle','ajaxIndex'],
  setup(props) {
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http
    const list = reactive({
      list: []
    })
    let dialogVisible = ref(false)
    let currentDetail = reactive({})


    const open = (item:any) => {
      console.log(item.id)
      if( props.handle === false ) {
        return false;
      }
      $http
        .get({
          url: "/product/articleDetail",
          params: {
            id:item.id
          }
        })
        .then((res:any) => {
          const {code, data} = res;

          if( code === 200 ){
            (currentDetail as any).value = data
            console.log(currentDetail)
            dialogVisible.value =(true)

            // tableList.list = data;
          }
        });
    }

    const addReadNum = (item:any) => {
      if( props.ajaxIndex ){
        $http
          .post({
            url: "/product/liveView",
            data: {
              liveId:item.id
            }
          })
      }else{
        $http
          .get({
            url: "/product/articleView",
            params: {
              type: item.type,
              id: item.id
            }
          })
      }


    }

    const handleClick = (item:any) => {
      if(  !item.active ){
        addReadNum(item)
      }
      item.active = !item.active;
    }
    onMounted(()=>{
      if( props.tableList && props.tableList.length ){
        list.list = props.tableList
      }
    })
    watch(props, function (value) {
      console.log('value.tableList',value.tableList)
      list.list = (value as any).tableList
    })
    return {
      list,
      dialogVisible,
      currentDetail,
      open,
      handleClick
    }
  }
});
