
import dayjs from "dayjs";
import {getCurrentInstance, reactive, ref} from 'vue'
import dialogVisible from '../../components/Jurisdiction.vue'

export default {
  name: "ExpertView",
  components: {
    dialogVisible
  },
  setup(){
    const data = reactive({
      list: [],
      expertImg: require('../../../assets/images/expert.png')
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    const $store = getCurrentInstance()?.appContext.config.globalProperties.$store;
    $http
      .get({
        url: "/ecmsLive/uncovering/list",
        params:{
          lastId:'',
          limit:5,
          direct:false,
        }
      })
      .then((res:any) => {
        data.list = res.data;
      });
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    let dialogVisible = ref(false)
    const goLogin = () => {
      if (Object.keys($store.state.loginInfo).length === 0) {
        $store.commit('setLoginStatus',1)
      } else {
        dialogVisible.value = true
      }
    }
    return {
      data,
      dateFliter,
      goLogin,
      dialogVisible
    }

  }
}
