import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
      class: "demo-tabs",
      onTabClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.list, (item, idx) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            label: item.tagDesc,
            key: idx
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.portfolioList, (innerI, innerIdx) => {
                return (_openBlock(), _createBlock(_component_el_button, {
                  key: innerIdx,
                  onClick: ($event: any) => (_ctx.goDetail(item,innerI))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(innerI.pname), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])
  ]))
}