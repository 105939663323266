
import { defineComponent,reactive,getCurrentInstance } from 'vue';
export default defineComponent({
  name: 'IAdviserpersonView',
  setup(){
    const data = reactive({
      list: []
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
      .get({
        url: "/investmentAdvisorMarketing/all/list",
      })
      .then((res:any) => {
        data.list = res.data;
      });
    return {
      data,
    }
  }
});
