<template>
  <div class="plate-box">
    <span>热门板块</span>
    <div class="content-box">
      <div class="item-box" v-for="(item,idx) in data.list" :key='idx'>
        <span>{{item.plateName}}</span>
        <span :style="styleColor(item.plateChangePercent)">{{item.plateChangePercent}}%</span>
      </div>
    </div>

  </div>
</template>

<script>
import { reactive,getCurrentInstance } from 'vue'
export default {
  name: "PlateView",
  setup(){
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    const data = reactive({
      list: []
    })
    $http
        .get({
          url: "/ecmsIndex/stockMarket/plate/ranking",
        })
        .then((res) => {
          data.list = res.data;
        });
   const styleColor = (item) => {
     return item < 0 ? 'color: #02C165;' : item > 0 ? 'color: #FB160C;' : 'color: #333333;'
   }
    return {
      data,
      styleColor
    }
  }
}
</script>

<style scoped lang="scss">
.plate-box {
  width: 100%;
  height: 177px;
  background: #FFFFFF;
  border-top: 2px #F51824 solid;
  > span {
    display: block;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    margin: 12px 0 0 20px;
  }
  > .content-box {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 20px 10px;
    > .item-box {
      width: 88px;
      height: 50px;
      background: #F2F4F5;
      margin-top: 10px;
      &:first-child, &:nth-child(2), &:nth-child(4), &:nth-child(5) {
        margin-right: 6px;
      }
      &:first-child, &:nth-child(2), &:nth-child(3) {
        margin-top: 0;
      }
      > span {
        display: block;
        &:first-child {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          text-align: center;
          margin-top: 5px;
        }
        &:last-child {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FB160C;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
