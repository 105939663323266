
import dayjs from "dayjs";
import { getCurrentInstance,reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: "FocusNews",
  setup(){
    const data = reactive({
      list: [],
      isOpen: 'item-sub',
      isCLose: 'item-sub-open',
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
      .get({
        url: "/ecmsNewsFlash/list",
        params: {
          limit: 3
        }
      })
      .then((res:any) => {
        data.list = res.data;
      });
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    const router = useRouter();
    const seeClick = () => {
      router.push({
        path: 'seven',
      })
    }
    const checkStyle = (status: any, subItem: any) => {
      subItem.isStatus = subItem.isStatus ? false : true
    }
    return {
      data,
      dateFliter,
      seeClick,
      checkStyle
    }

  }
}
