import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../assets/images/video.png'


const _withScopeId = n => (_pushScopeId("data-v-1f52970a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recent-box" }
const _hoisted_2 = { class: "left-box" }
const _hoisted_3 = { class: "top" }
const _hoisted_4 = { class: "img-box" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "center-box" }
const _hoisted_7 = { class: "bottom-box" }
const _hoisted_8 = { class: "left" }
const _hoisted_9 = { class: "button-box" }
const _hoisted_10 = { class: "right" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "bottom" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "left" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "right" }
const _hoisted_17 = { class: "bottom-box" }
const _hoisted_18 = { class: "left" }
const _hoisted_19 = { class: "button-box" }
const _hoisted_20 = { class: "right" }
const _hoisted_21 = { class: "right-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popular_information = _resolveComponent("popular-information")!
  const _component_dialog_visible = _resolveComponent("dialog-visible")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.updataList, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item-box",
            key: index
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: item.cover,
                alt: ""
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", null, _toDisplayString(item.label), 1)
                ]),
                _createElementVNode("span", null, _toDisplayString(item.publishTime.slice(0,10)), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", null, "播放量：" + _toDisplayString(item.playView) + "次", 1)
              ])
            ]),
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              onClick: ($event: any) => ($setup.clickVideo(item))
            }, null, 8, _hoisted_11)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.monthlyList, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item-box",
            key: item.id,
            onClick: ($event: any) => ($setup.clickGo(item))
          }, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("img", {
                src: item.cover,
                alt: ""
              }, null, 8, _hoisted_15)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("span", null, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("span", null, _toDisplayString(item.label), 1)
                  ]),
                  _createElementVNode("span", null, _toDisplayString($setup.dateFliter(item.publishTime,'MM-DD HH:mm')), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("span", null, "阅读量：" + _toDisplayString(item.playView) + "次", 1)
                ])
              ])
            ])
          ], 8, _hoisted_13))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createVNode(_component_popular_information)
    ]),
    _createVNode(_component_dialog_visible, {
      dialogVisible: $setup.dialogVisible,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.dialogVisible = false))
    }, null, 8, ["dialogVisible"])
  ]))
}