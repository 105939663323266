
import { defineComponent } from "vue";
import * as echarts from 'echarts'

export default defineComponent({
  name: "PieChartView",
  props:{
    allData:Object
  },
  setup(props){
    console.log((props as any).allData)
    const chatClass = 'chart-'+Math.floor(Math.random() * 1000000 + 1);
    const drawLine = () => {
      const box: HTMLElement = document.getElementById(chatClass) as HTMLElement;
      let myChart = echarts.init(box);

      const plist = (props as any).allData;
      let data1 = [];
      let data2 = [];
      let xAxisData = [];

      for(let i = 0; i < plist.length; i++) {
        xAxisData.push(plist[i].name);

        data1.push(Number(plist[i].exchangeEarnings).toFixed(2));
        data2.push(Number(plist[i].gains).toFixed(2));
      }

      let itemStyle = {
        normal: {
          // barBorderRadius: [20,20,0,0]
        },
        emphasis: {
          barBorderWidth: 1,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
        }
      };

      let option = {
        color: ['#3B7FEE', '#FE9625'],
        legend: {
          data: ['已兑现收益', '浮盈'],
          align: 'left',
          left: 0
        },
        tooltip: {},
        xAxis: {
          data: xAxisData,
          // name: 'X Axis',
          silent: false,
          axisLine: {
            onZero: true
          },
          splitLine: {
            show: false
          },
          splitArea: {
            show: false
          },
          axisLabel:{
              rotate : 60
          }
        },
        yAxis: {
          splitArea: {
            show: false
          }
        },
        grid: {
          left: 50,
          right: 0,
        },
        series: [{
            name: '已兑现收益',
            type: 'bar',
            stack: 'one',
            itemStyle: itemStyle,
            data: data1
          },
          {
            name: '浮盈',
            type: 'bar',
            stack: 'one',
            itemStyle: itemStyle,
            data: data2
          },
        ]
      };

      window.onresize = function () {
        myChart.resize();
      };
      myChart.setOption(option);
    }
    // onMounted(()=>{
    //   drawLine();
    // })
    return {
      chatClass,
      drawLine
    }
  }
});
