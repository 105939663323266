
import { defineComponent,computed } from 'vue';
import HeaderView from '@/views/components/Header.vue';
import FooterView from '@/views/components/Footer.vue';
import LoginView from '@/views/components/Login.vue';
import RegisterView from '@/views/components/Register.vue';
import ForgetPass from "@/views/components/ForgetPass.vue";

import { useStore } from "vuex"

export default defineComponent({
  name: 'LayoutView',
  components:{
    ForgetPass,
    HeaderView,
    FooterView,
    LoginView,
    RegisterView
  },
  setup(){
    const store = useStore()

    const state = computed(() => { // 使用计算属性来监听数据的变化
			return store.state
		})

    return {
      state
    }
  }
});
