import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27bfb2c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_view = _resolveComponent("header-view")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_view = _resolveComponent("footer-view")!
  const _component_login_view = _resolveComponent("login-view")!
  const _component_register_view = _resolveComponent("register-view")!
  const _component_forget_pass = _resolveComponent("forget-pass")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_view),
    _createVNode(_component_router_view, { class: "router-view" }),
    _createVNode(_component_footer_view),
    (_ctx.state.loginStatus === 1)
      ? (_openBlock(), _createBlock(_component_login_view, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.state.loginStatus === 2)
      ? (_openBlock(), _createBlock(_component_register_view, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.state.loginStatus === 3)
      ? (_openBlock(), _createBlock(_component_forget_pass, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}