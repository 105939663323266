import { createStore } from 'vuex'

export default createStore({
  state: {
    isOut: false,
    loginStatus: 0,
    loginInfo:localStorage.getItem('loginInfo') ? JSON.parse((localStorage.getItem('loginInfo') as any)) : {}
  },
  getters: {
  },
  mutations: {
    setLoginStatus(state,props) {
      state.loginStatus = props;
    },
    setLoginInfo() {
      // state.loginStatus = 0;
      // state.loginInfo = props;
      window.location.reload()
    },
    signOut(state, is) {
      state.loginStatus = 0;
      state.loginInfo = {};
      localStorage.clear();
      if( !is ){
        window.location.reload()
      }
    },
    setOut(state, boole){
      state.isOut = boole
    }
  },
  actions: {
  },
  modules: {
  }
})
