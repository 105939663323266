
import LoginAccount from './LoginAccount.vue'
import LoginPhone from './LoginPhone.vue'
import { defineComponent } from "vue"
import { useStore } from 'vuex'
import type { TabsPaneContext } from 'element-plus'

export default defineComponent({
  name: "LoginView",
  components:{
    LoginAccount,
    LoginPhone
  },
  setup(){
    const store = useStore();

    const handleClick = (tab: TabsPaneContext, event: Event) => {
      console.log(tab, event)
    }
    const handleClose = () => {
      if( store.state.isOut ){
        store.commit('setLoginInfo',0)
      }
      store.commit('setLoginStatus',0)
    }

    return {
      activeName:'first',
      dialogVisible:true,
      handleClick,
      handleClose
    }
  }
});
