
import { defineComponent,reactive,getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import COneTable from './COneTable.vue'
export default defineComponent({
  name: 'CurrentPosition',
  // props:{
  //   allData: Object
  // },
  components:{
    COneTable
  },
  setup(props){
    const router = useRouter();
    const query = (router as any).currentRoute.value.query
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http
    let tableData = reactive({
      list: []
    })

    //组合动态
    $http
      .get({
        url: "/product/holdStock",
        params: {
          productId: query.pid,//43,
        }
      })
      .then((res:any) => {
        const {code, data} = res;
        if( code === 200 ){
          tableData.list = data;
        }
      });

    // watch(props, function (value) {
    //   tableData.list = (value as any).allData.holding
    //   console.log('间听到了',(value as any).allData.holding)
    // })
    // console.log($moment().format('YYYY-MM-DD'))
    return {
      tableData,
    }
  }
  
});
