<template>
  <div class="wealth-details-box">
    <div class="title-box">
      <span>{{data.wealthList.title}}</span>
    </div>
    <div class="sub-title">
      <span>{{data.wealthList.label}}</span>
      <span></span>
      <span>{{$moment(data.wealthList.publishTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
    </div>
    <div class="main-points">
      <span>精华要点</span>
      <p>{{ data.wealthList.smallContent }}</p>
    </div>
    <div class="audio-box">
      <img :src="data.wealthList.cover" alt="">
      <audio :src="data.wealthList.audioUrl" v-if="data.wealthList.audioUrl" controls controlsList="nodownload"></audio>
    </div>
    <div class="content-box" v-html="data.wealthList.content">
    </div>
    <div :class="data.moreStyle">
      <P>{{data.moreStyle === 'isclaimers-box' ? data.claimersText.slice(0, 150) + '...' : data.claimersText }}</P>
      <span v-if="data.moreStyle === 'isclaimers-box'" @click="clickMore">更多</span>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive } from 'vue'
import { useRoute } from "vue-router";
export default {
  name: "WealthDetails",
  setup() {
    const data = reactive({
      wealthList: [],
      moreStyle: 'isclaimers-box',
      claimersText: '免责声明：陕西巨丰投资资讯有限责任公司（以下简称"巨丰投顾"）出品的所有内容、观点取决于市场上相关研究报告作者所知悉的各种市场环境因素及公司内在因素。盈利预测和目标价格的给予是基于一系列的假设和前提条件，因此，投资者只有在了解相关标的在研究报告中的全部信息基础上，才可能对我们所表达的观点形成比较全面的认识。巨丰投顾出品内容仅为对相关标的研究报告部分内容之引用或者复述，因受技术或其它客观条件所限无法同时完整提供各种观点形成所基于的假设及前提等相关信息，相关内容可能无法完整或准确表达相关研究报告的观点或意见，因而仅供投资者参考之用，投资者切勿依赖。任何人不应将巨丰投顾出品内容包含的信息、观点以及数据作为其投资决策的依据，巨丰投顾发布的信息、观点以及数据有可能因所基于的研究报告发布日之后的情势或其他因素的变更而不再准确或失效，巨丰投顾不承诺更新不准确或过时的信息、观点以及数据，所有巨丰投顾出品内容或发表观点中的信息均来源于已公开的资料，我公司对这些信息的准确性及完整性不作任何保证。巨丰投顾出品内容信息或所表达的观点并不构成所述证券买卖的操作建议。相关内容版权仅为我公司所有，未经书面许可任何机构和个人不得以任何形式转发、翻版、复制、刊登、发表或引用。'
    })
    const router = useRoute();
    let wealthId = router.query.wealthId;
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
        .get({
          url: "/web/stockRes/cfnc/detail",
          params: {
            id: wealthId
          }
        })
        .then((res) => {
          console.log(data, 'data')
          data.wealthList = res.data
        });
    const clickMore = () => {
      data.moreStyle = 'isclaimers-box-more'
    }
    return {
      data,
      clickMore
    }

  }
}
</script>

<style scoped lang="scss">
.wealth-details-box {
  background: #FFFFFF;
  > .title-box {
    width: 1080px;
    padding-top: 33px;
    margin-left: 60px;
    > span {
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 45px;
    }
  }
  > .sub-title {
    display: flex;
    align-items: center;
    margin-left: 60px;
    margin-top: 10px;
    > span {
      display: block;
      &:first-child {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      &:nth-child(2) {
        width: 1px;
        height: 16px;
        background-color: #999999;
        margin: 0 10px;
      }
      &:last-child {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
    }
  }
  > .main-points {
    width: 1080px;
    height: 118px;
    background: #F8FAFB;
    margin: 30px auto 20px auto;
    padding: 15px 20px;
    > span {
      display: block;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
    > p {
      margin-top: 8px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
    }
  }
  > .audio-box {
    margin: 0 auto;
    text-align: center;
    > img {
      width: 271px;
      height: 203px;
      margin: 0 auto;
      display: block;
    }
    audio{
      width: 600px;
      height: 30px;
      margin-top: 27px;
    }
  }
  > .content-box {
    width: 1080px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    margin: 20px auto 43px auto;
    ::v-deep img{
      max-width: 100%;
    }
    ::v-deep p{
      //margin-bottom: 8px;
      padding: 8px 0;
      font-size: 16px;
      line-height: 32px;
      text-align: justify;
      text-justify:distribute-all-lines;
    }
  }
  > .isclaimers-box {
    width: 1080px;
    margin: 0 auto;
    position: relative;
    > p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      text-align: justify;
      text-justify:distribute-all-lines;
    }
    > span {
      display: block;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #164091;
      line-height: 24px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  > .isclaimers-box-more {
    width: 1080px;
    margin: 0 auto;
    > p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      text-align: justify;
      text-justify:distribute-all-lines;
    }
  }
}

</style>

