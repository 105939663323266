
import { defineComponent,reactive,getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import CSixTable from './CSixTable.vue'

export default defineComponent({
  name: 'CSix',
  components:{
    CSixTable
  },
  // props:{
  //   allData: Object
  // },
  setup(props){
    const router = useRouter();
    const query = (router as any).currentRoute.value.query
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http
    const tableData = reactive({
      list: []
    })
    //根据组合产品Id 获取历史表现数据
    $http
      .get({
        url: "/product/stock/history",
        params: {
          productId: query.pid,//43,
        }
      })
      .then((res:any) => {
        const {code, data} = res;
        if( code === 200 ){
          tableData.list = data;
        }
      });
    // watch(props, function (value) {
    //   tableData.list = (value as any).allData.history
    // })

    return {
      tableData,
    }
  }
});
