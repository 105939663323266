/*
 * @FilePath: \ihaogu-pc-new\src\router\cxclass.js
 * @Author: wanglipeng
 * @Date: 2021-09-14 10:58:01
 * @LastEditTime: 2021-09-27 16:34:12
 * @LastEditors: wanglipeng
 * @Description:翠霞课程-新浪合作内嵌页面路由集合
 */
const prefix = 'cx';
const evn = window.location.host !== 'www.haoguvip.com';
export default [
    {
        name: prefix + 'OpenClass',
        path: 'T3BlbkNsYXNz',
        meta: {
            title: '公开课程页面',
            pid: evn ? 183 : 273
        },
        component: () => import('@/views/cxclass/OpenClass/index.vue')
    },

    // {
    //     name: prefix + 'OpenClassDetail',
    //     path: 'T3BlbkNsYXNzDetail',
    //     meta: {
    //         title: '公开课程-详情'
    //     },
    //     component: () => import('@/views/cxclass/OpenClass/detail.vue')
    // },
    {
        name: prefix + 'LevelOne',
        path: 'TGV2ZWxPbmU',
        meta: {
            title: '初级实战课程页面',
            pid: evn ? 185 : 274
        },
        component: () => import('@/views/cxclass/LevelOne/index.vue')
    },
    // {
    //     name: prefix + 'LevelOneDetail',
    //     path: 'TGV2ZWxPbmUDetail',
    //     meta: {
    //         title: '初级实战课-详情'
    //     },
    //     component: () => import('@/views/cxclass/LevelOne/detail.vue')
    // },
    {
        name: prefix + 'TradingGuide',
        path: 'VHJhZGluZ0d1aWRl',
        meta: {
            title: '操作指南',
            pid: evn ? 182 : 279
        },
        component: () => import('@/views/cxclass/TradingGuide/index.vue')
    },
    {
        name: prefix + 'TradingGuideDetail',
        path: 'VHJhZGluZ0d1aWRlDetail',
        meta: {
            title: '操作指南-详情'
        },
        component: () => import('@/views/cxclass/TradingGuide/detail.vue')
    },

    {
        name: prefix + 'WealthConnection',
        path: 'V2VhbHRoQ29ubmVjdGlvbg',
        meta: {
            title: '财富连线',
            pid: evn ? 190 : 281
        },
        component: () => import('@/views/cxclass/WealthConnection/index.vue')
    },
    // {
    //     name: prefix + 'WealthConnectionDetail',
    //     path: 'V2VhbHRoQ29ubmVjdGlvbgDetail',
    //     meta: {
    //         title: '财富连线-详情'
    //     },
    //     component: () => import('@/views/cxclass/WealthConnection/detail.vue')
    // }
    
];
