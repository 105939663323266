
import {defineComponent, getCurrentInstance, reactive, ref} from 'vue';
import FocusNews from '../home/components/FocusNews.vue'
import {useRoute} from "vue-router";
import dayjs from "dayjs";
import dialogVisible from '../components/Jurisdiction.vue'
export default defineComponent({
  name: 'DetailView',
  components: {
    FocusNews,
    dialogVisible
  },
  setup() {
    const data = reactive({
      infodList: []
    })
    const router = useRoute();
    let infodId = router.query.infodId;
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
        .get({
          url: "/ecmsArticle/detail",
          params: {
            id: infodId
          }
        })
        .then((res:any) => {
          data.infodList = res.data;
        });
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    let dialogVisible = ref(false)
    const goDaild = () => {
      dialogVisible.value = true
    }
    return {
      data,
      dialogVisible,
      dateFliter,
      goDaild
    }

  }
});
