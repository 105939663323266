
import { defineComponent,reactive,getCurrentInstance } from "vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: "CTab",
  props:{
    title:{
      type: String,
    },
    isMore:{
      type: String,
    },
    itemData:{
      type: Object
    }
  },
  setup(props,{emit}){
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    const mapData = reactive({
      list:{}
    })
    const dataList = reactive({
      list:[
        {
          name:'成立以来',
          id:'vlist_all',
          type:1
        },
        {
          name:'月初以来',
          id:'vlist_m0',
          type:2
        },
        {
          name:'近1月',
          id:'vlist_m1',
          type:3
        },
        {
          name:'近3月',
          id:'vlist_m3',
          type:5
        },
        {
          name:'近6月',
          id:'vlist_m6',
          type:6
        },
        {
          name:'年初以来',
          id:'vlist_y0',
          type:7
        },
        {
          name:'近1年',
          id:'vlist_y1',
          type:8
        },
      ],
      curIdx : 2
    })

    const handleClick = (idx:any,item:any) =>{
      let key = dataList.list[idx].id;
      if( (mapData as any).list[key] ){
        emit('tabClick',(mapData as any).list[key])
        dataList.curIdx = idx
      }else{
        $http
          .get({
            url: "/product/earningData",
            params: {
              productId: (props as any).itemData.productId,//query.pid,//43,
              type: dataList.list[idx].type
            }
          })
          .then((res:any) => {
            const {code, data} = res;
            if( code === 200 ){
              if( !data.length ){
                ElMessage.error('暂无数据')
              }else{
                (mapData as any).list[key] = data;
                emit('tabClick',data)
                dataList.curIdx = idx
              }
            }
          });
      }

      // if( props.itemData && props.itemData.portfolioData[item.id] && props.itemData.portfolioData[item.id].length ){
      //   emit('tabClick',props.itemData.portfolioData[item.id])
      //   data.curIdx = idx
      // }else{
      //   ElMessage.error('暂无数据')
      // }
    }

    return {
      dataList,
      handleClick,
    }
  }
});
