
import { defineComponent,getCurrentInstance,reactive } from "vue";
import  {useRouter} from "vue-router";
export default defineComponent({
  name: "NavView",
  setup(){
    const router = useRouter(); 

    const data = reactive({
      list: []
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
        .get({
          url: "/product/web/portfolioList"
        })
        .then((res:any) => {
          const {code} = res;
          if( code === 200 ){
            // ElMessage.success(msg)
            data.list = res.data;
          }else{
            // ElMessage.error(msg)
          }
        });

    const goDetail = (item:any,innerItem:any)=>{
      console.log(innerItem)
      router.push({
        path:"/cd",
        query:{
          tagId: item.tagId,
          pid: innerItem.pid
        }
      });
    }

    return {
      data,
      goDetail
    }
  }
});
