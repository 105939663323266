
import PopularInformation from './PopularInformation.vue'
import dialogVisible from '../../components/Jurisdiction.vue'
import {getCurrentInstance, reactive, ref} from 'vue'
import { useStore } from "vuex"
import dayjs from "dayjs";
export default {
  name: "RecentUpdates",
  components: { PopularInformation, dialogVisible },
  setup() {
    const data = reactive({
      updataList: [],
      show: false,
      monthlyList: []
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
        .get({
          url: "/web/stockRes/newest/videos",
          params: {
            pageNum: 1,
            pageSize: 4
          }
        })
        .then((res: any) => {
          data.updataList = res.data.list;
        });
    $http
        .get({
          url: "/web/stockRes/ydcl/list",
          params: {
            pageNum: 1,
            pageSize: 3
          }
        })
        .then((res: any) => {
          data.monthlyList = res.data.list;
        });
    const clickGo = (item: any) => {
      if (Object.keys($store.state.loginInfo).length === 0) {
        $store.commit('setLoginStatus',1)
      } else if (item.payStatus) {
        window.open(item.url)
      } else {
        dialogVisible.value = true
      }
    }
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    let dialogVisible = ref(false)
    const store = useStore()
    const $store = getCurrentInstance()?.appContext.config.globalProperties.$store
    const clickVideo = (item: any) => {
      if (Object.keys($store.state.loginInfo).length === 0) {
        store.commit('setLoginStatus',1)
      } else {
        if (item.payStatus) {
          if (item.isXet) {
            $http
                .post({
                  url: "/ecmsVideoLive/xet/liveUrl/redirect",
                  data: {
                    loginType: 1,
                    xetUrl: item.url,
                    xetResourceId: item.xetResourceId
                  }
                })
                .then((res: any) => {
                  const { url } = res.data
                  window.open(url)
                });

          } else {
            window.open(item.url)
          }
        } else {
          dialogVisible.value = true
        }
      }
    }
    return {
      data,
      dialogVisible,
      clickGo,
      clickVideo,
      dateFliter
    }

  }
}
