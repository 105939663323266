/** webpack自带的
 * 开发环境：development
 * 生产环境：production
 * 测试环境：test
 */
let BASE_URL = "";
const TIME_OUT = 10000;
console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
  BASE_URL = "//thaogupiao-api.jufxt.com";
} else if (process.env.NODE_ENV == "production") {
  BASE_URL = "//api.haoguvip.com";
  // BASE_URL = "//thaogupiao-api.jufxt.com";
} else {
  // BASE_URL = "//api.haoguvip.com";
  BASE_URL = "//thaogupiao-api.jufxt.com";
}

export {BASE_URL, TIME_OUT};