
import { defineComponent,ref,getCurrentInstance } from "vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: "LoginView",
  components:{
  },
  props:{
    phone:String,
    opType:String
  },
  setup(props){
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;

    const time = ref(60);
    const isDisposed = ref(false);

    const getCode = () => {
      if( !isDisposed.value ){
        isDisposed.value = true;
        let params = {
          phone : props.phone
        }
        if( props.opType ){
          (params as any).opType = props.opType;
        }
        $http
          .post({
            url: "/login/verifyCode/send",
            data:params
          })
          .then((res:any) => {
              const {code, msg} = res;
              if( code === 200 ){
                handleTimeChange();
                ElMessage.success(msg)
              }else{
                ElMessage.error(msg)
                isDisposed.value = false;
              }
          }).catch(()=>{
            isDisposed.value = false;
          });
      }
    }

    const handleTimeChange = () => {
      if (time.value <= 0) {
        isDisposed.value = false;
        time.value = 60;
      } else {
        setTimeout(() => {
          time.value--;
          handleTimeChange();
        }, 1000);
      }
    };


    return {
      time,
      isDisposed,
      getCode,
    }
  }
});
