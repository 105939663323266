
import { defineComponent } from "vue";
import * as echarts from 'echarts'

export default defineComponent({
  name: "PieChartView",
  props:{
    allData:Object,
    pname:String
  },
  setup(props){
    const chatClass = 'chart-'+Math.floor(Math.random() * 1000000 + 1);
    const drawLine = () => {
      const box: HTMLElement = document.getElementById(chatClass) as HTMLElement;

      let myChart = echarts.init(box);
      const hydict = (props as any).allData;
      let arr:any = []
      for (const hydictKey in hydict) {
        arr.push({
          value:Number(hydict[hydictKey].count).toFixed(2),//.toFixed(2) ,
          name:hydict[hydictKey].industryName
        })
      }
      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'center',
          right: '80px',
          orient: 'vertical',
          textStyle:{
            fontSize: '16',
          },
          formatter: function (name:any) {
            let filter = arr.filter((item:any)=>item.name === name)[0]
            return filter.name + ' ' + filter.value + '%'
            // return name
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '80%'],
            center: [arr.length > 7 ? '20%' : '35%', '50%'],
            avoidLabelOverlap: false,
            label: {//饼图中间文字设置
              normal: {
                show: true,
                position: 'center',
                fontSize: '16',
                color:'#9A9EBA',
                formatter: () => {
                  // const name = `(props as any).allData.basic.name}`;
                  // if( name.length < 7 ) {
                  //   return name
                  // }else{
                  //   let new
                  // }
                  return props.pname;
                }
              },
              emphasis: {//中间文字显示
                show: true,
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: arr
          }
        ]
      };
      window.onresize = function () {
        myChart.resize();
      };
      myChart.setOption(option);
    }
    // onMounted(()=>{
    //   drawLine();
    // })
    return {
      chatClass,
      drawLine
    }
  }
});
