import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { useStore } from "vuex"
import '@/assets/css/reset.css';
// import login from "@/views/components/Login.js"; // 登录弹窗全局组件

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import http from "./service/index";
import moment from 'moment'
import utils from '@/libs/utils'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import footerView from './views/components/Footer.vue'

const storeUse = useStore();
const app = createApp(App);
app.config.globalProperties.$http = http;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$store = storeUse;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('FooterView', footerView)
// app.use(login);
app.use(store).use(router).use(ElementPlus).mount('#app')
