
import { defineComponent,onMounted,getCurrentInstance } from "vue";
import * as echarts from 'echarts'

export default defineComponent({
  name: "LineChartView",
  props:{
    itemData:{
      type: Object,
      default: ()=>{
        return {
          portfolioData: {
            vlist_all:{}
          }
        }
      }
    }
  },
  components:{
  },
  setup(props){
    console.log(props)
    const chatClass = 'chart-'+Math.floor(Math.random() * 100 + 1);
    const $utils = getCurrentInstance()?.appContext.config.globalProperties.$utils;

    const getServies = ( params:any ) => {

      let dataMap:any = {};
      let serveiesMap:any = [];

      //数据拼装  {0:[],1:[]}
      params.map((item:any,idx:any)=>{
        let index = 0
        for (const itemKey in item) {
          if( itemKey !== 'date' ){
            if( !dataMap[itemKey] ){
                dataMap[itemKey] = [item[itemKey]]
            } else {
              dataMap[itemKey].push(item[itemKey])
            }
          }
          index++
        }
      })

      console.log(dataMap)

      //几条线循环几次
      for (const dataMapKey in dataMap) {
        console.log(props.itemData.productName)
        serveiesMap.push({
            name:dataMapKey,
            color: $utils.getLineColor(props.itemData.productName == dataMapKey ? '组合' : dataMapKey),
            type: "line",
            symbol: "none",
            data: dataMap[dataMapKey],
            areaStyle: getAreaStyle(dataMapKey)
          })
      }
      return serveiesMap;
    }

    const getAreaStyle = ( name:any ) => {
      if( name.indexOf('指数') === -1 ){
        return null
      }
      return {
        //区域填充样式
        normal: {
          //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
          color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              .8,
              [
                {
                  offset: 0.5,
                  color: "rgba(0, 134, 251, 0.29)",
                },
                {
                  offset: 1,
                  color: "rgba(59, 127, 238, 0)",
                },

              ],
              false
          ),
          // shadowColor: "rgba(109, 126, 0, 0.5)", //阴影颜色
          shadowBlur: -330, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
        }
      }
    }

    const drawLine = () => {
      let xData:any = [];
      // let xSeriesData1:any = [];
      // let xSeriesData2:any = [];

      let mapServies:any = getServies(props.itemData.portfolioData.vlist_m1);

      props.itemData.portfolioData.vlist_m1.map((item:any)=>{
        xData.push(item['date'])
        // xData.push(item[0].value)
        // xSeriesData1.push(item[3].value)
        // xSeriesData2.push(item[2].value)
        // item.map((innerItem:any)=>{
        //   if( innerItem.text === 'date' ){
        //     xData.push(innerItem.value)
        //   }else if( innerItem.text === props.itemData.productName ){
        //     xSeriesData2.push(innerItem.value)
        //   }else if( innerItem.text === '上证指数' ){
        //     xSeriesData1.push(innerItem.value)
        //   }
        // })

        // xData.push(item['date'])
        // xSeriesData1.push(props.itemData.productName.toFixed(2))
        // xSeriesData2.push(item['上证指数'].toFixed(2))
      })

      const box: HTMLElement = document.getElementById(chatClass) as HTMLElement;

      let myChart = echarts.init(box);

      let option = {
        backgroundColor: "transparent",
        // color: ["#fff","green"],
        grid: {
          left: "4%",
          right: "5%",
          top: "0%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          axisTick: {show: false},
          boundaryGap: false,
          axisLabel: {
            show: false, //隐藏刻度值
          },
          axisLine: {
            show:false,
          },
          data: xData//["3月", "4月", "5月", "6月", "7月", "8月","3月", "4月", "5月", "6月", "7月", "8月","3月", "4月", "5月", "6月", "7月", "8月"],
          //   data: ["2018年", "2019年", "2020年", "2021年", "2022年", "2023年"],
        },
        yAxis: {
          name: "",
          nameTextStyle: {
            color: "#CCCCCC",
            // fontSize: this.WidthAdaptive(12),
            // padding: this.WidthAdaptive(8),
          },
          axisTick: {show: false},
          axisLabel: {
            show: false, //隐藏刻度值
            // margin: this.WidthAdaptive(10),
            // textStyle: {color: "#CCCCCC", fontSize: this.WidthAdaptive(12)},
          },
          
          axisLine: {
            show: false,
            lineStyle: {color: "#cccccc", type: "solid"},
          },
          splitLine: false,
          boundaryGap: [0, 0.1],
        },
        series: mapServies
        //     [
        //   {
        //     name: "地面",
        //     color: '#0086FB',
        //     type: "line",
        //     // symbolSize: this.WidthAdaptive(6),
        //     symbol: "none",
        //     data: xSeriesData1,//[40, 65, 68, 59, 54, 69,61, 65, 96, 59, 54, 69,61, 65, 96, 59, 54, 69],
        //     itemStyle: {
        //         normal: {
        //             lineStyle: {
        //               width:1// 0.1的线条是非常细的了
        //             }
        //         }
        //     },
        //     areaStyle: {
        //       //区域填充样式
        //       normal: {
        //         //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
        //         color: new echarts.graphic.LinearGradient(
        //             0,
        //             0,
        //             0,
        //             .8,
        //             [
        //               {
        //                 offset: 0.5,
        //                 color: "rgba(0, 134, 251, 0.29)",
        //               },
        //               {
        //                 offset: 1,
        //                 color: "rgba(59, 127, 238, 0)",
        //               },
        //
        //             ],
        //             false
        //         ),
        //         // shadowColor: "rgba(109, 126, 0, 0.5)", //阴影颜色
        //         shadowBlur: -330, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
        //       },
        //     },
        //   },
        //   {
        //     name: "地面",
        //     color: '#FB160C',
        //     type: "line",
        //     // symbolSize: this.WidthAdaptive(6),
        //     symbol: "none",
        //     data: xSeriesData2,//[10, 10, 10, 26, 26, 30,16, 26, 26, 26, 26, 26,26, 30, 30, 30, 30, 30],
        //     itemStyle: {
        //         normal: {
        //             lineStyle: {
        //               width:1// 0.1的线条是非常细的了
        //             }
        //         }
        //     },
        //     areaStyle: {
        //       //区域填充样式
        //       normal: {
        //         //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
        //         color: 'transparent'
        //         // shadowColor: "rgba(109, 126, 0, 0.5)", //阴影颜色
        //         // shadowBlur: this.WidthAdaptive(20), //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
        //       },
        //     },
        //   },
        // ],
      };
      window.onresize = function () {
        myChart.resize();
      };
      myChart.setOption(option);
    }

    onMounted(() => {
      drawLine();
    })
    return {
      chatClass
    }
  }
});
