
import {getCurrentInstance, onMounted, reactive} from "vue";
import MyCombination from "@/views/home/components/MyCombination.vue";
import * as echarts from "echarts";
export default {
  name: "PopularCombination",
  components:{
    MyCombination,
  },
  setup(){
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    // const $store = getCurrentInstance()?.appContext.config.globalProperties.$store;


     //热门个股接口
    const tableData = reactive({
      list: [],
      hotData:[],
      historicalList: [], // 历史收益
      hasBuyList: ""
    })
    $http
        .get({
          url: "/product/hotStock/applies"
        })
        .then((res:any) => {
          const {code,data} = res;
          if( code === 200 ){
            data.map((item:any)=>{
              item.earnings = Number(item.earnings).toFixed(2)
              item.applies = Number(item.applies).toFixed(2)
              item.earningsGreen = Number(item.earnings) <= 0 ? true : false
              item.appliesGreen = Number(item.applies) <= 0 ? true : false
            })
            console.log(1234)
            console.log(data)
            // data = data.splice(0,3)
            tableData.list = data.splice(0,4);
            // ElMessage.success(msg)
            // hotdata.list = data;
          }else{
            // ElMessage.error(msg)
          }
    });
    $http
        .get({
          url: "/product/hotPortfolio/pearnings"
        })
        .then((res:any) => {
          const {code,data} = res;
          if( code === 200 ){
            console.log(data, '------------------------------------历史收益')
            // data.map((item:any)=>{
            //   item.earnings = Number(item.earnings).toFixed(2)
            //   item.applies = Number(item.applies).toFixed(2)
            //   item.earningsGreen = Number(item.earnings) <= 0 ? true : false
            //   item.appliesGreen = Number(item.applies) <= 0 ? true : false
            // })
            tableData.historicalList = data.splice(0,3);
          }
    });

    //热门组合
    // $http
    //     .get({
    //       url: "/product/hotPortfolio/capplies"
    //     })
    //     .then((res:any) => {
    //       const {code,data} = res;
    //       if( code === 200 ){
    //                     tableData.hotData = data
    //
    //       }else{
    //         // ElMessage.error(msg)
    //       }
    // });

    // ------ 绘图Fn--------------
    const mappingFn = () => {

      let chartDom = document.getElementById('main')!;
      chartDom.removeAttribute('_echarts_instance_');

      type EChartsOption = echarts.EChartsOption;

      let myChart = echarts.init(chartDom);
      let option: EChartsOption | undefined;
      myChart.showLoading();
      $http
          .get({
            url: "/product/hotPortfolio/capplies"
          })
          .then((res:any) => {
            const {code,data} = res;
            if (code === 200) {
              console.log(data, '处理前的数据')
              // let newData: any = [];
              // data.concept.map((item: any) => {
              //   let objItem = {
              //     name: item.conceptName,
              //     value: Number(item.incr)
              //   }
              //   newData.push(objItem)
              // })
              if (data == undefined) {
                return false;
              }
              let newData: any = [];
              // console.log(data.sort((a:any, b:any) => {
              //   return a.portfolioBasic.incr - b.portfolioBasic.incr
              // }), '---------------11111')
              // console.log(data, '000000000-------')
              data.forEach((item: any, index: any) => {
                let green = "#27B666";
                let red = "#E22C1F";
                let white = "#fff";
                let obj = {
                  name: item.productName,
                  // value: 1.5,
                  value: Math.abs(item.portfolioBasic.incr),
                  // value: Math.abs(item.portfolioBasic.incr) < 10 ? Math.abs(item.portfolioBasic.incr) * 100 : Math.abs(item.portfolioBasic.incr) / 50,
                  isNegative: Number(item.portfolioBasic.incr),
                  label: {
                    show: true,
                    fontSize: 15,
                    color: '#fff',
                    // align: "center",
                    // verticalAlign: "middle",
                    formatter: function (a: any) {
                      let prefix = Number(a.data.isNegative) > 0 ? "+" : "";
                      // let prefix = Number(a.data.isNegative) > 0 ? "+" : Number(a.data.isNegative) < 0 ? "-" : "";
                      // let prefix = Number(a.incr) > 0 ? "+" : "";
                      return (
                          a.name +
                          "\n" +
                          prefix +
                          Number(a.data.isNegative).toFixed(2) +
                          "%"
                      );
                    },
                  },
                  itemStyle: {
                    color: Number(item.portfolioBasic.incr) < 0 ? green : Number(item.portfolioBasic.incr) == 0 ? '#ccc' : red,
                  },
                };
                if (index < 10) {
                  newData.push(obj);
                }
              });
              newData.sort((a:any, b: any) => {
                return a.value - b.value
              })
              let dataList: any = [];
              newData.map((item: any) => {
                let arr = {
                  name: item.name,
                  label: item.label,
                  itemStyle: item.itemStyle,
                  isNegative: item.isNegative,
                  value: item.value > 50 ? 5.5 : item.value < 30 ? 2.5 : 3.5
                };
                dataList.push(arr)
              })
              console.log(dataList, '--------------------处理后的数据')
              myChart.hideLoading();
              myChart.setOption(
                  (option = {
                    // tooltip: {
                    //   formatter: function (info: any) {
                    //     return [
                    //       '<div class="tooltip-title">' +
                    //       info.name +
                    //       '</div>',
                    //       Number(info.data.isNegative).toFixed(2) + '%'
                    //     ].join('');
                    //   }
                    // },
                    series: [
                      {
                        nodeClick: "link",
                        type: "treemap",
                        width: "100%",
                        height: "100%",
                        squareRatio: 1.1,
                        breadcrumb: {
                          show: false,
                        },
                        itemStyle: {
                          gapWidth: 5,
                        },
                        label: {
                          color: "#000",
                          // formatter: `{b}\n{c}%`,
                          // position: "insideBottomLeft",
                        },
                        roam: false,
                        silent: false,
                        color: ["#E22C1F"],
                        data: dataList,
                      },
                    ],
                  })
              );
            }
          });
      option && myChart.setOption(option);
    }
    // // -----------------------
    onMounted(() => {
      setTimeout(() => {
        mappingFn();
      }, 1000)
    })
    $http
        .get({
          url: "/product/hasBuy"
        })
        .then((res:any) => {
          const {code,data} = res;
          if( code === 200 ){
            console.log(data, 'data--------------')
            tableData.hasBuyList = data.hasBuy
          }else{
            // ElMessage.error(msg)
          }
    });


    const backgroundStyle = (item: any) => {
      return item < 0 ? 'background: #5CBC7C;' : item > 0 ? 'background: #F5504B;' : 'background: #cccccc;'
    }

    const dtList = reactive({
      list:[]
    })

    const getDtList = (data:any) => {
      dtList.list = data
    }

    const zxtcList = reactive({
      list:[]
    })
    const getZxtcList = (data:any) => {
      console.log('data.....',data)
      zxtcList.list = data
    }
    // const init = () => {
    //   setTimeout(() => {
    //     mappingFn()
    //   }, 600)
    // }
    return {
      tableData,
      dtList,
      zxtcList,
      // init,
      getDtList,
      getZxtcList,
      backgroundStyle
    }
  }
}
