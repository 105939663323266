import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70529782"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList.list, (item, idx) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass({ 'active' : idx == _ctx.dataList.curIdx }),
          onClick: _withModifiers(($event: any) => (_ctx.handleClick(idx,item)), ["stop"]),
          key: idx
        }, _toDisplayString(item.name), 11, _hoisted_2))
      }), 128))
    ])
  ]))
}