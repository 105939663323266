
import { defineComponent,computed } from 'vue';
import { useStore } from "vuex"

export default defineComponent({
  name: 'HeaderView',
  setup(){
    const store = useStore()
    //打开登录弹窗
    const openDialog = () => {
      store.commit('setLoginStatus',1)
    }

    const loginInfo = computed(() => {
      console.log('store',store.state.loginInfo)
      return store.state.loginInfo
    })

    return {
      openDialog,
      loginInfo
    }
  }
});
