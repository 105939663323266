
import { reactive,getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
// import { Base64 } from 'js-base64';


export default {
  setup(){
    const store = useStore();
    const form = reactive({})
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
   


    const onSubmit = () => {
      let obj = JSON.parse(JSON.stringify(form))
      obj.password = window.btoa(obj.password)
      $http
        .post({
          url: "/login/logon",
          data:obj
        })
        .then((res:any) => {
          const {code, msg, data} = res;
          if( code === 200 ){
            localStorage.setItem('loginInfo',JSON.stringify(data))
            store.commit('setLoginInfo', data)
            ElMessage.success(msg)
          }else{
            ElMessage.error(msg)
          }
        });
    }
    const handleToRegister = () => {
      store.commit('setLoginStatus',2)
    }

    
    return {
      form,
      onSubmit,
      handleToRegister
    }
  }
}
