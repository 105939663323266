
// import dayjs from "dayjs";
import { getCurrentInstance,reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: "SeniorAdviser",
  setup(){
    const data = reactive({
      list: []
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
      .get({
        url: "/investmentAdvisorMarketing/random/list"
      })
      .then((res: any) => {
        data.list = res.data;
      });
    const router = useRouter();
    const goDetails = () => {
      router.push({
        path: 'iap',
      })
    }
    return {
      data,
      goDetails
    }

  }
}
