
import PopularInformation from '../StockMarketResearch/components/PopularInformation.vue'
import {getCurrentInstance, reactive} from "vue";
import dayjs from "dayjs";
export default {
  name: "SevenDetailPage",
  components: { PopularInformation },
  setup(){
    const data = reactive({
      sevenList:reactive<Array<string|undefined>>([]),
      showBtn: true
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
        .get({
          // url: "/ecmsNewsFlash/list",
          url: "/ecmsNewsFlash/list/format",
          params: {
            limit: 8
          }
        })
        .then((res:any) => {
          data.sevenList = res.data;
        });
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    const seeMore = ()=> {
      let lastId = ''
      data.sevenList.forEach((item: any, index) => {
        if (data.sevenList.length - 1 === index) {
          item.data.forEach((subItem: any, subIndex: any) => {
            if (item.data.length - 1 === subIndex) {
              lastId = subItem.id
            }

          })
        }
      })
      $http
          .get({
            url: "/ecmsNewsFlash/list/format",
            params: {
              lastId,
              limit: 8
            }
          })
          .then((res:any) => {
            // data.sevenList = data.sevenList.concat(res.data);
            // data.sevenList.forEach((item: any) => {
            //   res.data.forEach((subItem: any) => {
            //     subItem.data.forEach((itemSub: any) => {
            //       if (item.label === subItem.label) {
            //         // console.log(item)
            //         // console.log(subItem, 'subItem--------------------')
            //         // console.log(itemSub, 'itemSub--------------------')
            //         // console.log(item.label, 'item')
            //         // console.log(subItem.label, 'subItem')
            //         item.data.push(itemSub)
            //         // console.log(item, 'item-----------------------item')
            //       } else {
            //         data.sevenList.concat(subItem);
            //       }
            //
            //     })
            //   })
            // })

            // let prevItem = data.sevenList;
            if (res.data.length > 0) {
              let response = res.data;
              if( data.sevenList.length && res.data.length ){
                let prevItem:any = data.sevenList[data.sevenList.length-1];
                if( response[0].label === prevItem['label'] ){
                  prevItem.data = [...prevItem.data,...response[0].data];
                  response.splice(0,1)
                }
              }
              data.sevenList.push(...res.data)
            } else {
              data.showBtn = false
            }
          });
    }
    return {
      data,
      dateFliter,
      seeMore
    }

  }
}
