
import { defineComponent,getCurrentInstance,reactive } from 'vue';
import List from './List.vue'
import { useRouter } from "vue-router";
export default defineComponent({
  name: 'CSeven',
  components:{
    List
  },
  setup() {
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http
    const router = useRouter();
    const query = (router as any).currentRoute.value.query
    const tableList = reactive({
      list: []
    })
    //之投资者
    $http
      .get({
        url: "/product/articleList",
        params: {
          type: 2,
          productId:query.pid//3
        }
      })
      .then((res:any) => {
        const {code, data} = res;
        if( code === 200 ){
          tableList.list = data;
        }
      });

    return {
      tableList
    }
  }

});
