
import { defineComponent,getCurrentInstance,reactive } from 'vue';
import CTitle from '../components/CTitle.vue'
import BrokenLineChart from '../components/ChartLine.vue';
import NavList from './components/NavList.vue'
import BasicPannel from './components/BasicPannel.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'DetailView',
  components:{
    NavList,
    BrokenLineChart,
    CTitle,
    BasicPannel
  },
  setup(){
    const chartHeight = '150px';
    const router = useRouter();
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    let data = reactive({
      tabTabIdx:0
    });

    //我的组合按钮切换
    const handleTabClick = (num:any) => {
      data.tabTabIdx = num;
      myPortfolio.portfolioData = myPortfolio.list[data.tabTabIdx]['portfolioData']['vlist_all'];
    }

    //我的组合接口
    const myPortfolio = reactive({
      list: [],
      portfolioData:[{}]
    })
    $http
        .get({
          url: "/product/myPortfolio"
        })
        .then((res:any) => {
          const {code} = res;
          if( code === 200 ){
            myPortfolio.list = res.data;
            if( res.data.length ){
              myPortfolio.portfolioData = res.data[0].portfolioData.vlist_m1;
            }
          }else{
            // ElMessage.error(msg)
          }
        });

    //热门个股接口
    const tableData = reactive({
      list: []
    })
    $http
        .get({
          url: "/product/hotStock/applies"
        })
        .then((res:any) => {
          const {code,data} = res;
          if( code === 200 ){
            // data.map((item:any)=>{
            //   item.earnings = Number(item.earnings).toFixed(2)
            //   item.applies = Number(item.applies).toFixed(2)
            //   item.earningsGreen = Number(item.earnings) <= 0 ? true : false
            //   item.appliesGreen = Number(item.applies) <= 0 ? true : false
            // })
            tableData.list = data;
            // ElMessage.success(msg)
          }else{
            // ElMessage.error(msg)
          }
        });


    const hotData = reactive({
      list: []
    })
    //热门组合接口
    $http
        .get({
          url: "/product/hotPortfolio/applies"
        })
        .then((res:any) => {
          const {code} = res;
          if( code === 200 ){
            // ElMessage.success(msg)
            hotData.list = res.data;
          }else{
            // ElMessage.error(msg)
          }
        });

    const pearningsData = reactive({
      list: []
    })
    //近期组合收益
    $http
        .get({
          url: "/product/hotPortfolio/earnings"
        })
        .then((res:any) => {
          const {code} = res;
          if( code === 200 ){
            // ElMessage.success(msg)
            pearningsData.list = res.data;
          }else{
            // ElMessage.error(msg)
          }
        });

    const goDetail = (item:any) => {
      router.push({
        path:"/cd",
        query:{
          pid: item.productId
        }
      });
    }
    return {
      chartHeight,
      data,
      tableData,
      hotData,
      myPortfolio,
      pearningsData,
      handleTabClick,
      goDetail
    }
  }
});
