
import { defineComponent,ref,watch } from 'vue';
export default defineComponent({
  name:'JurisdictionView',
  props: ['dialogVisible'],
  setup(props){
    let opacity = ref(false)

    watch(props, function (value) {
      opacity.value = (value as any).dialogVisible;
    })

    return {
      opacity
    }
  }
})

