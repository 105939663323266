
import { reactive,getCurrentInstance,ref } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import GetCodeBtn from "@/views/components/GetCodeBtn.vue";
export default {
  components:{
    GetCodeBtn
  },
  setup(){
    const store = useStore();
    const form = reactive({
      username:''
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    
    const time = ref(60);
    const isDisposed = ref(false);

    const getCode = () => {
      if( !isDisposed.value ){
        isDisposed.value = true;
        $http
          .post({
            url: "/login/verifyCode/send",
            data:{
              phone : form['username']
            }
          })
          .then((res:any) => {
              const {code, msg} = res;
              if( code === 200 ){
                handleTimeChange();
                ElMessage.success(msg)
              }else{
                ElMessage.error(msg)
              }
              isDisposed.value = false;
          }).catch(()=>{
            isDisposed.value = false;
          });
      }
    }

    const handleTimeChange = () => {
      if (time.value <= 0) {
        isDisposed.value = false;
        time.value = 60;
      } else {
        setTimeout(() => {
          time.value--;
          handleTimeChange();
        }, 1000);
      }
    };

    const onSubmit = () => {
      $http
        .post({
          url: "/login/logon",
          data:form
        })
        .then((res:any) => {
          const {code,data, msg} = res;
          if( code === 200 ){
            // localStorage.setItem('user',JSON.stringify(data));
            localStorage.setItem('loginInfo',JSON.stringify(data))
            store.commit('setLoginInfo', data)
            ElMessage.success(msg)
          }else{
            ElMessage.error(msg)
          }
        });
    }

    const handleToRegister = () => {
      store.commit('setLoginStatus',2)
    }

    return {
      form,
      time,
      getCode,
      onSubmit,
      handleToRegister
    }
  }
}
