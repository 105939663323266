import HYRequest from "./request";
import {BASE_URL, TIME_OUT} from "./request/config";
import store from "@/store"

const hyRequest = new HYRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      //携带token拦截
      const info = (localStorage as any).getItem('loginInfo');
      const userinfo = info ? JSON.parse(info) : {};
      const configs = config.headers as any;
// console.log('userinfo')
// console.log(userinfo)
      if (userinfo.token) {
        // configs.Authorization = `Bearer${userinfo.token}`;
        configs.token = userinfo.token;
        configs.userid = userinfo.userId;
      }
      // configs.token = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuaWNrbmFtZSI6ImhncF8xNjEwMTYxOCIsImF2YXRhciI6Imh0dHBzOi8vamZpbmZvLm9zcy1jbi1iZWlqaW5nLmFsaXl1bmNzLmNvbS9haGcvZGVmYXVsdC9oZWFkZXIuanBnIiwidXNlcklkIjoiMTE1MjEzIiwidXNlcm5hbWUiOiIxNTcwMTU3MDAyNSJ9.Tk35_dKqk38uPQZVtYugo6Mh1N-g0gO7K8nSZLFyXrg`;
      // configs.userid = `115213`;
      
      // console.log("请求成功的拦截");
      return config;
    },
    requestInterceptorCatch: (err) => {
      console.log("请求失败的拦截");
      return err;
    },
    responseInterceptor: (res) => {
      if( res.data.code ===  108 || res.data.code ===  113 ){
        if( store.state.loginStatus !== 1 ){
          
          store.commit('setOut', true)
          store.commit('signOut', true)
          store.commit('setLoginStatus', 1)
        }
      }
      return res;
    },
    rrequestInterceptorCatch: (err) => {
      console.log("响应失败的拦截");
      return err;
    }
  }
});

export default hyRequest;