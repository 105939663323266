
import CTitle from '../components/CTitle.vue'
import BrokenLineChart from '../components/ChartLine.vue';
import COne from './components/COne.vue'
import CTwo from './components/CTwo.vue'
import CThree from './components/CThree.vue'
import CFour from './components/CFour.vue'
import CFive from './components/CFive.vue'
import CSix from './components/CSix.vue'
import CSeven from './components/CSeven.vue'
import CEight from './components/CEight.vue'
import CNine from './components/CNine.vue'
import BasicPannel from './components/BasicPannel.vue'
import List from './components/List.vue'
import Drawer from './drawer/index.vue'
import Jurisdiction from '../components/Jurisdiction.vue'
import { useRouter,useRoute } from "vue-router";
import { defineComponent,ref,getCurrentInstance,reactive } from 'vue';
import type { TabsPaneContext } from 'element-plus'
export default defineComponent({
  name: 'DetailView',
  components:{
    CTitle,
    // BrokenLineChart,
    COne,
    CTwo,
    CThree,
    CFour,
    CFive,
    CSix,
    CSeven,
    CEight,
    CNine,
    BasicPannel,
    BrokenLineChart,
    List,
    Drawer,
    Jurisdiction
  },
  setup(){
    const router = useRouter();
    const route = useRoute();
    const activeName = ref('0')
    const topNavList = reactive({
      list: []
    })
    const portfolioData = reactive({
      data: {}
    })
    const qxList = reactive({
      list: {}
    })
    const tableList = reactive({
      list: []
    })
    const menuList = reactive({
      list: []
    })
    const three = ref(null);
    const query = (router as any).currentRoute.value.query
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http
    const $store = getCurrentInstance()?.appContext.config.globalProperties.$store
    let pid = Number(query.pid)
    let drawer = ref(false)
    let params = reactive({
      code:'',
      productId:''
    })
    let jurisdiction = ref(false)

    //组合动态
    $http
      .get({
        url: "/product/lives",
        params: {
          productId: query.pid,//43,
          pageNum: 1,
          pageSize: 10
        }
      })
      .then((res:any) => {
        const {code, data} = res;
        if( code === 200 ){
          tableList.list = data.list;
        }
      });


    $http
      .get({
        url: "/product/combin/menu/list",
        params: {
          productId: query.pid//33,
        }
      })
      .then((res:any) => {
        const {code, data} = res;
        if( code === 200 ){
          menuList.list = data
        }
      });

    const productDetailsFn = () => {
      console.log('已切换')
      //根据产品id获取组合产品详情
      $http
          .get({
            url: "/product/portfolio",
            params: {
              productId: query.pid//2,
            }
          })
          .then((res:any) => {
            const {code, data} = res;
            if( code === 200 || code === 403 ){
              // data.portfolioData = data;
              portfolioData.data = data;
            }
            getEearningData()
          });
    }
    productDetailsFn()
    // //根据产品id获取组合产品详情
    // $http
    //   .get({
    //     url: "/product/portfolio",
    //     params: {
    //       productId: query.pid//2,
    //     }
    //   })
    //   .then((res:any) => {
    //     const {code, data} = res;
    //     if( code === 200 || code === 403 ){
    //       // data.portfolioData = data;
    //       portfolioData.data = data;
    //     }
    //     getEearningData()
    //   });

    //根据产品id获取收益曲线
    const getEearningData = ()=> {
      $http
        .get({
            url: "/product/earningData",
            params: {
              productId: query.pid,//query.pid,//43,
              type: 3
            }
        })
        .then((res:any) => {
            const {code, data} = res;
            if( code === 200 ){
              (qxList as any).list.vlist_m1 = data;
              (qxList as any).list.productId = query.pid;
              if( (portfolioData.data as any)['basic'] ){
                (qxList as any).list.productName = (portfolioData.data as any)['basic']['name'];
              }
            }
        });
    }


    const handleClick = (tab: TabsPaneContext, event: Event) => {
      console.log(tab, event)
      if( tab.props.label === '组合分析' ){
        // console.log(three.value[0])
        (three as any).value[0].init();
      }
    }

    const look = (item:any) => {

      if( Object.keys($store.state.loginInfo).length === 0 ){
        $store.commit('setLoginStatus',1)
        return false;
      }
      if( item.code === '***' ) {
        jurisdiction.value = true;
        return false;
      }
      params.code = item.code
      params.productId = (route as any).query.pid
      drawer.value = true
    }
    // const handleNavTab = (item:any) => {
    //   // console.log(item)
    //   // pid = item.pid
    // }
const checkTab = () => {
  console.log(111)
  productDetailsFn()
}
    return {
      activeName,
      topNavList,
      pid,
      portfolioData,
      qxList,
      tableList,
      menuList,
      drawer,
      params,
      jurisdiction,
      three,
      handleClick,
      look,
      productDetailsFn,
      checkTab
      // handleNavTab
    }
  }
});
