const numberFixed = (data:any, count:any) => {
  return checkNumber(data).toFixed(count);
};
const checkNumber = (data:any) => {
  return typeof data === 'number' ? data : Number(data);
};
const prefixSuffix = (data:any, prefix:any, suffix:any) => {
  if (data == undefined || data == null) {
      return null;
  }
  if (prefix !== undefined) {
      data = prefix + data;
  }
  if (suffix !== undefined) {
      data = data + suffix;
  }
  return data;
};
const mktcap = (data:any, n:any, m:any, suffix:any) => {
  if (data == undefined) {
      return 0;
  }
  data = numberFixed(data / n, m);
  return prefixSuffix(data, undefined, suffix);
};
const dealUpDate = (str:any) => {
  if (typeof str === 'undefined') {
      return '0000/00/00';
  }

  if (typeof str !== 'string') {
      str = String(str);
  }

  const temp = [];
  const prefix = '/';
  for (let i = 0; i < str.length; i++) {
      if (i == 4 || i == 6) {
          temp.push(prefix);
      }
      temp.push(str.charAt(i));
  }
  return temp.join('');
};

const opMapColor = (data:any) => {
  const types = {
      CASHIN: 'info', // 资金
      SELL: 'success', // 卖出
      BUY: 'danger', // 买入
      FQ: 'info' // 复权
  };
  const labels = {
      CASHIN: '资金', // 资金
      SELL: '卖出', // 卖出
      BUY: '买入', // 买入
      FQ: '复权' // 复权
  };
  return { type: (types as any)[data], label: (labels as any)[data] ? (labels as any)[data] : '操作' };
};

 /**
     * @description: 特殊线条配色-映射方法处理
     * @param {*} name
     * @return {*}
     */
    const getLineColor = (name:any) => {
      const obj:any = {
        上证指数: '#0185FB',//蓝色
        持仓: '#0ff',
        组合: '#F51824',//红色
        选股: '#ce2c1e',
        沪深: '#FB8F15',
      };
      for (const key in obj) {
        if (name.indexOf(key) > -1) {
          return obj[key];
        }
      }
    };


/**
 * @description: 红绿class判断
 * @param {*} id
 * @return {*}
 */
const colorClass = (value:any) =>{
  return value >= 0 ? 'red' : 'green'
}
/**
 * @description: 红绿class判断 0灰色
 * @param {*} id
 * @return {*}
 */
const colorClassZero = (value:any) =>{
    if( value > 0 ){
        return 'red';
    }else if( value < 0 ){
        return 'green';
    }else{
        return 'gray'
    }
}



/**
 * @description: 股票数值文字拼接
 * @param {*} id
 * @return {*}
 */
 const numText = (value:any) =>{
  if( value !== null && value !== undefined ){
      if( typeof value !== 'number'){
          value = Number(value)
      }
      return value > 0 ? `+${value.toFixed(2)}%` : value.toFixed(2) + '%'
  }
}


export default {
  colorClass,
  colorClassZero,
  numText,
  mktcap,
  dealUpDate,
  opMapColor,
  numberFixed,
  prefixSuffix,
    getLineColor
};
