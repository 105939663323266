import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25e8245d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_table, {
      "scrollbar-always-on": true,
      data: _ctx.tableData,
      style: {"width":"100%"},
      "row-class-name": _ctx.tableRowClassName,
      border: "",
      "empty-text": "暂无数据",
      "default-sort": {prop:'incr',order:'descending'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "name",
          label: "股票名称",
          sortable: "",
          width: _ctx.tableData[0]?.code === '***' ? 'auto' : '115',
          align: _ctx.tableData[0]?.code === '***' ? 'center' : 'left',
          fixed: "left"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.name), 1),
            _createElementVNode("p", null, _toDisplayString(scope.row.code.slice(0,6)), 1)
          ]),
          _: 1
        }, 8, ["width", "align"]),
        _createVNode(_component_el_table_column, {
          prop: "incr",
          label: "实时涨幅",
          sortable: "",
          width: _ctx.tableData[0]?.code === '***' ? 'auto' : '115',
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$utils.colorClass(scope.row.incr))
            }, _toDisplayString(_ctx.$utils.numText(scope.row.incr)), 3)
          ]),
          _: 1
        }, 8, ["width"]),
        _createVNode(_component_el_table_column, {
          prop: "hincr",
          label: "持仓收益",
          sortable: "",
          width: _ctx.tableData[0]?.code === '***' ? 'auto' : '115',
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$utils.colorClass(scope.row.hincr))
            }, _toDisplayString(_ctx.$utils.numText(scope.row.hincr)), 3)
          ]),
          _: 1
        }, 8, ["width"]),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              prop: "maxincr",
              label: "最大涨幅",
              sortable: "",
              width: "115",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$utils.colorClass(scope.row.maxincr))
                }, _toDisplayString(_ctx.$utils.numText(scope.row.maxincr)), 3)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 1,
              prop: "turnover",
              label: "换手率",
              sortable: "",
              width: "105",
              align: "center"
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 2,
              prop: "mvalue",
              label: "市值",
              width: "100",
              sortable: "",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$utils.mktcap(scope.row.mvalue, 10000, 2, '万')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 3,
              prop: "weight",
              label: "权重",
              width: "110",
              sortable: "",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(scope.row.weight.toFixed(2)), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 4,
              prop: "num",
              label: "持股数",
              sortable: "",
              width: "105",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(scope.row.num) + "股 ", 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 5,
              prop: "price",
              label: "最新价",
              sortable: "",
              width: "105",
              align: "center"
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 6,
              prop: "bprice",
              label: "买入均价",
              sortable: "",
              width: "120",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(scope.row.bprice.toFixed(3)), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 7,
              prop: "minincr",
              label: "最小涨幅",
              sortable: "",
              width: "120",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$utils.colorClass(scope.row.minincr))
                }, _toDisplayString(_ctx.$utils.numText(scope.row.minincr)), 3)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 8,
              prop: "industry1",
              label: "行业",
              width: "100",
              sortable: "",
              align: "center"
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 9,
              prop: "industry2",
              label: "细分",
              width: "130",
              sortable: "",
              align: "center"
            }))
          : _createCommentVNode("", true),
        (_ctx.tableData[0]?.code !== '***')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 10,
              prop: "ctype",
              label: "类型",
              width: "130",
              sortable: "",
              align: "center"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_table_column, {
          prop: "percent",
          label: "操作",
          width: _ctx.tableData[0]?.code === '***' ? 'auto' : '100',
          sortable: "",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", {
              class: "watch",
              onClick: ($event: any) => (_ctx.$emit('look',scope.row))
            }, " 查看 ", 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["width"])
      ]),
      _: 1
    }, 8, ["data", "row-class-name"])
  ]))
}