
import {defineComponent,getCurrentInstance, reactive, ref, onMounted, watch} from 'vue'
import dialogVisible from '../../components/Jurisdiction.vue'
import dayjs from "dayjs";
export default defineComponent({
  name: "MasterClass",
  components: {
    dialogVisible
  },
  props:{
    tableList:Object,
    has:Boolean
  },
  setup(props) {
    const data = reactive({
      classList: [],
      show: false,
      pageNum: 1
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    const $store = getCurrentInstance()?.appContext.config.globalProperties.$store
    let isBottom = true;
    const getList = () => {
      if( isBottom ) {
        $http
          .get({
            url: "/web/stockRes/dsk/list",
            params: {
              pageNum: data.pageNum++,
              pageSize: 6
            }
          })
          .then((res: any) => {
            data.classList = data.classList.concat(res.data.list);
            if (res.data.list.length < 6) {
                isBottom = false;
            }
          });
       }
    }

    let dialogVisible = ref(false)
    const clickVideo = (item: any) => {
      if(Object.keys($store.state.loginInfo).length === 0 ){
        $store.commit('setLoginStatus',1)
        return false;
      }
      if (item.payStatus) {
        if (item.isXet) {
          $http
              .post({
                url: "/ecmsVideoLive/xet/liveUrl/redirect",
                data: {
                  loginType: 1,
                  xetUrl: item.url,
                  xetResourceId: item.xetResourceId
                }
              })
              .then((res: any) => {
                const { url } = res.data
                window.open(url)
                clickView(item)
              });
        } else {
          window.open(item.url)
          clickView(item)
        }
      } else {
        dialogVisible.value = true
      }
    }
    const clickView = (item: any) => {
      $http
          .get({
            url: "/web/stockRes/content/view",
            params: {
              type: item.type,
              id: item.id
            }
          })
          .then((res: any) => {
            console.log(res);
          });
    }
    const dateFliter = (val:any, format = "YYYY-MM-DD hh:mm:ss") => {
      if (!isNaN(val)) {
        val = parseInt(val);
      }
      return dayjs(val).format(format);
    };
    const seeMore = ()=> {
      if( !(props as any).has ) {
        getList()
      }
    }

    onMounted(()=>{
      if( !(props as any).has ){
        getList()
      }
    })

    watch(props, function (value) {
      data.classList = (value as any).tableList.list;
    })
    return {
      data,
      dialogVisible,
      clickVideo,
      seeMore,
      dateFliter
    }
  }
})
