
import {getCurrentInstance, reactive} from "vue";
import { useRouter } from 'vue-router'

export default {
  name: "PopularInformation",
  setup() {
    const data = reactive({
      newsList: []
    })
    const $http = getCurrentInstance()?.appContext.config.globalProperties.$http;
    $http
        .get({
          url: "/ecmsArticle/recommend/list",
          params: {
            limit: 4
          }
        })
        .then((res: any) => {
          data.newsList = res.data;
        });
    const router = useRouter();
    const goDetails = (item: any) => {
      console.log(item, '详情')
      router.push({
        path: 'infod',
        query: { infodId: item.id}
      })
    }
    return {
      data,
      goDetails
    }
  }
}
