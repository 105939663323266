<template>
  <div class="stock">
    <Tabs @clickTab="clickTab" :class="data.typeTag === 0 ? 'tableClass' : ''" />
<!--    最近更新-->
    <recent-updates v-if="data.typeTag === 0" />
<!--    大师课-->
    <master-class v-if="data.typeTag === 1" />
<!--    投研课-->
    <investment-research-course v-if="data.typeTag === 2" />
<!--    财富内参-->
    <wealth-internal-reference v-if="data.typeTag === 4" />
<!--    股市脉动-->
    <stock-market-pulsation v-if="data.typeTag === 3" />
<!--    月度策略-->
    <monthly-strategy v-if="data.typeTag === 5" />
    <customer-service-popup />
  </div>
<!--  <footer-view />-->
</template>

<script>
import Tabs from './components/Tabs'
import RecentUpdates from './components/RecentUpdates.vue'
import MasterClass from './components/MasterClass.vue'
import StockMarketPulsation from './components/StockMarketPulsation.vue'
import MonthlyStrategy from './components/MonthlyStrategy.vue'
import InvestmentResearchCourse from './components/InvestmentResearchCourse.vue'
import WealthInternalReference from './components/WealthInternalReference.vue'
import CustomerServicePopup from './components/CustomerServicePopup'
import { reactive } from 'vue'
export default {
  name: 'StockMarket',
  components: {
    Tabs,
    RecentUpdates,
    MasterClass,
    StockMarketPulsation,
    MonthlyStrategy,
    InvestmentResearchCourse,
    WealthInternalReference,
    CustomerServicePopup
  },
  setup() {
    const data = reactive({
      typeTag: 0
    })
    const clickTab = (item) => {
      data.typeTag = item.type
    }
    return {
      data,
      clickTab
    }
  }

}
</script>

<style scoped lang="scss">
.stock {
  width: 1200px;
  min-height: 880px;
  margin: 0 auto;
  padding-top: 20px;
}
.tableClass {
  width: 880px;
}
::v-deep .information{
  margin-top: 24px;
}
</style>
